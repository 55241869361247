import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { UserService } from '@app/_services/user.service';
import { environment } from "@environments/environment";
import * as firebase from 'firebase';
import { OneSignalService } from 'ngx-onesignal';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  currentUser = { id: null, email: "", ticketsOpened: [], teams: [] };
  httpOptions;
  lastOrder;

  currentTitle;

  constructor(
    private afdb: AngularFireDatabase,
    private UserService: UserService,
    public readonly onesignal: OneSignalService,
    private cs: CacheService,
    private http: HttpClient
  ) {
    this.currentUser = this.UserService.getCurrentUser();
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic " + environment.oneSignal.restApiKey
      })
    };
  }

  getNotifications() {

  }
  saveNotification(msg) {
    return;
  }

  sendOneSignal(text, tags, title?, mod?) {
    const filter = [];
    tags.map((key, index) => {
      if (index > 0) filter.push({ operator: "OR" });
      filter.push({ field: "tag", key: key, relation: "=", value: true });

      const id = this.afdb.createPushId();
      const not: any = { tag: key, text, createdAt: firebase.database.ServerValue.TIMESTAMP }
      if (title) not.title = title;
      this.afdb.list('notifications').set(id, not);

    });
    this.http
      .post(
        environment.apiUrl.oneSignal,
        {
          app_id: environment.oneSignal.appId,
          filters: filter,
          data: { module: mod },
          contents: { en: text }
        },
        this.httpOptions
      )
      .subscribe(
        data => {
          console.log("POST Request is successful ", data);
        },
        error => {
          console.log("Error", error);
        }
      );
  }

  sendtag(tag, value) {
    (<any>window).ngxOnesignal.push([
      "sendTag",
      "" + tag,
      value,
      function (tagsSent) {
        console.log(tagsSent);
      }
    ]);
  }

  renewOneSignal() {
    (<any>window).ngxOnesignal = this.onesignal;
    //Protegendo onesignal e deixando o player id sempre atualizado
    var req = indexedDB.deleteDatabase('ONE_SIGNAL_SDK_DB');
    req.onsuccess = function () {
      console.log("Deleted database successfully");
    };
    req.onerror = function () {
      console.log("Couldn't delete database");
    };
    req.onblocked = function () {
      console.log("Couldn't delete database due to the operation being blocked");
    };

  }

  loadTags() {
    if (localStorage.getItem('tagsLoaded')) return;
    const self = this;
    if ((<any>window).ngxOnesignal)
      setTimeout(() => {
        console.log('limpando tags');
        (<any>window).ngxOnesignal.push([
          "getTags",
          function (result) {
            (<any>window).ngxOnesignal.push([
              "deleteTags", Object.keys(result),
              function (result) {
                self.renewOneSignal();
                const tags = [];
                self.currentUser.teams.map(r => r.id).map(r => tags.push([r]));
                self.currentUser.ticketsOpened.map(r => tags.push([r]));
                tags.push([self.currentUser.id]);
                if ((<any>window).ngxOnesignal)
                  setTimeout(() => {
                    tags.map(tag => self.sendtag(tag, true));
                  }, 2000);
              }
            ]);
          }
        ]);
      }, 2000);
  }

  deleteTags(tags?: Array<String>) {
    let t = [];

    setTimeout(() => {
      (<any>window).ngxOnesignal.push([
        "getTags",
        function (result) {
          t = Object.keys(result);
          console.log(t);
        }
      ]);
      console.log(t);
      setTimeout(() => {
        (<any>window).ngxOnesignal.push([
          "deleteTags", t,
          function (result) {
            console.log(result);
          }
        ]);
      }, 10000);
    }, 10000);
  }
} 
