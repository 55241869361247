<main class="main">
    <app-navbar></app-navbar>
    <section class="content">
        <div *ngIf="loading" style="display: flex;height: 50vh;">
            <div class="page-loader__spinner">
                <svg viewBox="25 25 50 50">
                    <circle cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
        </div>
        <router-outlet></router-outlet>
    </section>
</main>