import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { firestoreService } from '@app/_services/firestore.service';
import { SharedService } from "@app/_services/shared.service";
import { UserService } from '@app/_services/user.service';
import { filter } from "rxjs/operators";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html"
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  currentUser: any;
  title = "";
  loaded = false;
  currentRoute = "";
  isMobile = false;
  auxMenu = false;
  shops;
  loading;

  statusSidebar;

  constructor(
    private router: Router,
    private titleService: Title,
    private fs: firestoreService,
    private UserService: UserService,
    private shared: SharedService
  ) {
    this.isMobile = /Android|iPhone/i.test(window.navigator.userAgent);
    this.currentUser = null;

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.toggleMenu();
      });
  }
  ngAfterViewInit() {
    this.loaded = true;
  }
  ngOnInit() {
    const self = this;
    this.title = this.titleService.getTitle();
    self.currentUser = this.UserService.getCurrentUser();
    if (this.router.url === '/') {
      this.loading = true;
      this.fs.colWithIds$('/infrastructure/access/permissions/', ref => ref.where('members', 'array-contains', self.currentUser.id)).subscribe(r => {
        r=r.reverse();
        r = r.filter(a => a.is_web)
        if (r && r.length > 0)
          this.router.navigate(["/" + r[0].slug]);
      }, null, () =>
        this.loading = false
      );
    }
  }


  ngOnDestroy() { }
  public toggleMenu() {
    this.auxMenu = !this.auxMenu;
  }

  public closeMenu() {
    this.auxMenu = false;
  }
}
