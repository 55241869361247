import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, ReplaySubject } from "rxjs";
import { take } from "rxjs/operators";
import { firestoreService } from "./firestore.service";

@Injectable({
  providedIn: "root"
})
export class CacheService {
  constructor(private fs: firestoreService) { }

  get(item: any) {
    const obs$ = new ReplaySubject();
    let dataStored;
    if (localStorage.getItem(item)) {
      dataStored = JSON.parse(localStorage.getItem(item));
    } else {
      dataStored = {};
      dataStored["data"] = [];
      dataStored["createdAt"] = null;
    }

    if (
      dataStored.locked !== "1" &&
      (!dataStored.createdAt ||
        moment(new Date()).diff(moment(dataStored.createdAt), "minutes") > 0)
    ) {
      dataStored["locked"] = 1;

      let last = dataStored.createdAt
        ? moment(dataStored.createdAt).toDate()
        : moment()
          .subtract(12, "year")
          .toDate();
      localStorage.setItem(item, JSON.stringify(dataStored));
      this.fs
        .colWithIds$(item, ref => ref.where("createdAt", ">", last))
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            if (!res || res.length == 0) return;
            dataStored.data = [...dataStored.data, ...res];
            this.save(item, dataStored);
          },
          null,
          () => {
            this.save(item, dataStored);
            obs$.next(prepara());
          }
        );
    } else {
      if (dataStored.locked !== "1")
        obs$.next(prepara());
    }

    function prepara() {
      return JSON.parse(localStorage.getItem(item))["data"].map(r => {
        if (r && r.createdAt && r.createdAt.seconds) r.createdAt = moment(r.createdAt.seconds * 1000).utc();
        if (r && r.updatedAt && r.updatedAt.seconds) r.updatedAt = moment(r.updatedAt.seconds * 1000).utc();
        return r;
      }
      )
    }
    return obs$.pipe(take(1)) as Observable<any>;
  }

  save(key, obj) {
    obj.locked = 0;
    obj.createdAt = new Date();
    localStorage.setItem(key, JSON.stringify(obj));
  }

  clearCache() {
    localStorage.removeItem("/infrastructure/entities/providers");
    localStorage.removeItem("/modules/survey/surveys");
    localStorage.removeItem("/modules/observatory/teams");
    localStorage.removeItem("/infrastructure/entities/providers/");
    localStorage.removeItem("/infrastructure/access/users/");
    localStorage.removeItem("/infrastructure/entities/units/");
    window.location.reload();
  }
}
