import { Component, OnInit } from "@angular/core";
import { AngularFireStorage } from '@angular/fire/storage';
import { Ticket } from '@app/_models/tickets';
import { CacheService } from "@app/_services/cache.service";
import { firestoreService } from "@app/_services/firestore.service";
import { NotificationService } from "@app/_services/notification.service";
import { UserService } from '@app/_services/user.service';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import '@ckeditor/ckeditor5-build-inline/build/translations/pt';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { OneSignalService } from "ngx-onesignal";
import "rxjs/add/observable/forkJoin";
import { Observable } from "rxjs/Observable";
import { finalize, take } from "rxjs/operators";
import { TicketComponent } from "./tickets/ticket/ticket.component";


@Component({
  selector: "app-observatory",
  templateUrl: "./observatory.component.html",
  styleUrls: ["./observatory.component.css"]
})
export class ObservatoryComponent implements OnInit {
  public Editor = InlineEditor;
  public config = {
    language: "pt", rows: 3, placeholder: `Descreva detalhadamente sua requisição aqui`
  };

  modalRef;
  showDropzone;
  tickets = { all: [], filtered: [], byMe: [], closed: [], expireds: [] };
  currentStatus;
  showAdminMenu;
  dtOptions: DataTables.Settings = {};
  isAdmin;
  stats = { all: 0, expired: 0, expiring: 0, forwardedByMe: 0, finished: 0 };
  defaultTimes = { toAttendsHigh: null, toAttendsLow: null, toAttendsMedium: null, toStarts: null }

  actives: boolean = true;

  newTicket: Ticket = new Ticket();
  quickModal = { show: false, minimized: false };
  teams = { my: null, all: null };
  currentUser: any;
  loadingTickets = true;
  loading: boolean;
  files: File[] = [];

  ticketSelected;
  checkedTickets = [];


  constructor(
    private modalService: NgbModal,
    public notificationService: NotificationService,
    private fs: firestoreService,
    private afStorage: AngularFireStorage,
    private cs: CacheService,
    public readonly onesignal: OneSignalService,
    private UserService: UserService
  ) {
    this.currentUser = this.UserService.getCurrentUser();
    const profiles: [String] = this.currentUser.profiles || [];
    (<any>window).ngxOnesignal = onesignal;

    setTimeout(() => {
      if (this.onesignal.isSubscribe) this.notificationService.loadTags();
    }, 8000);
    this.onesignal.subscribe();
  }

  ngOnInit() {
    this.dtOptions.language = {
      lengthMenu: "Mostrando _MENU_ registros por página",
      zeroRecords: "Nada encontrado",
      info: "Mostrando página _PAGE_ de _PAGES_",
      infoEmpty: "Nenhum registro",
      infoFiltered: "(filtrado de _MAX_)"
    };
    this.dtOptions.responsive = true;
    this.dtOptions.order = [6, "desc"];

    this.fs.doc$('/modules/observatory').subscribe((r: any) => this.defaultTimes = r.defaultTimes);

    this.dtOptions.paging = true;
    this.dtOptions.info = true;
    this.loadTeams();
  }

  selectTicket(ticket) {
    this.checkedTickets = [];
    this.tickets.all.map((r: any) => (r.checked = false));
    this.ticketSelected = ticket;
  }

  closeTicket() {
    this.tickets.all.filter(
      (r: any) => r.id === this.ticketSelected.id
    )[0] = this.ticketSelected;
    this.ticketSelected = false;
  }

  checkTickets(ticket) {
    if (!ticket.checked) {
      ticket.checked = true;
      this.checkedTickets.push(ticket);
    } else {

      ticket.checked = false
      this.checkedTickets = this.checkedTickets.filter(r => r.id !== ticket.id);
    }
  }

  filter(status?) {
    this.dtOptions.columnDefs = [
      {
        targets: [3],
        visible: true
      }
    ];
    this.loadingTickets = true;

    if (status !== "fromMe" && !status) this.loadTickets();
    else this.loadingTickets = false;
    console.log(this.tickets);

    this.tickets.filtered = [];
    this.currentStatus = status;
    if (status) {
      if (status === "expiring")
        this.tickets.filtered = this.tickets.all.filter(
          r => r.expire > 0 && r.expire <= 300
        );
      if (status === "expired")
        this.tickets.filtered = this.tickets.all.filter(r => this.checkIfIsLate(r));
      if (status === "Finalizado") {
        this.tickets.filtered = this.tickets.all.filter(
          r => r.status === status
        );
      }
    } else this.tickets.filtered = this.tickets.all;
  }

  checkIfIsLate(ticket) {
    return ((ticket.ma && ticket.ma <= 0) || (ticket.ms && ticket.ms <= 0)) && ticket.status === "Novo";
  }

  loadTeams() {
    this.fs.colWithIds$(`/modules/observatory/teams`)
      .pipe(take(1))
      .subscribe(
        r => {
          this.teams.all = r.sort((a, b) => (a & b && a.name && b.name) ? a.name.localeCompare(b.name) : false);;
          this.teams.my = r.filter(
            res =>
              res &&
              res.members &&
              res.members.some(e => e.id === this.currentUser.id)
          );
        },
        null,
        () => this.loadTickets()
      );
  }

  loadMy() {
    this.tickets.filtered = [];
    this.loadingTickets = true;
    this.dtOptions.columnDefs = [
      {
        targets: [3],
        visible: false
      }
    ];
    this.currentStatus = "fromMe";
    let q;
    q = this.fs
      .colWithIds$("/modules/observatory/tickets", ref =>
        ref.where("requester.id", "==", this.currentUser.id).where("finalizedAt", "==", null)
      )
    if (!this.actives)
      q = this.fs
        .colWithIds$("/modules/observatory/tickets", ref =>
          ref.where("requester.id", "==", this.currentUser.id)
        )
    q.subscribe(
      (r: any) => {
        this.tickets.filtered = r;
      },
      null,
      () => (this.loadingTickets = false)
    );
  }


  loadForwardedByMe() {
    this.tickets.filtered = [];
    this.loadingTickets = true;
    this.currentStatus = "forwardedByMe";
    this.fs
      .colWithIds$("/modules/observatory/tickets", ref =>
        ref.where("forwardedBy.id", "==", this.currentUser.id)
      )
      .subscribe(
        r => (this.tickets.filtered = r),
        null,
        () => (this.loadingTickets = false)
      );
  }


  onSelect(event) {
    this.files.push(...event.addedFiles);
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  upload(file) {
    return new Promise(resolve => {
      const filePath = Math.random().toString(36).substring(2);
      const fileRef = this.afStorage.ref(filePath);
      this.afStorage.upload(filePath, file).snapshotChanges().pipe(
        finalize(() => fileRef.getDownloadURL().subscribe(r => resolve(r)))
      ).subscribe()
    });
  }

  loadTickets() {
    this.dtOptions.columnDefs = [
      {
        targets: [3],
        visible: true
      }
    ];
    this.loadingTickets = true;
    this.tickets.all = [];
    const myTeams = this.teams.my.map(r => (r = r.id)) || [];
    const obs = [];
    obs.push(
      this.fs.colWithIds$("/modules/observatory/tickets", ref =>
        ref.where("assignedTo.id", "==", this.currentUser.id)
      )
    );
    if (myTeams.length > 0) {
      obs.push(
        this.fs.colWithIds$("/modules/observatory/tickets", ref =>
          ref.where("teamRequested.id", "in", myTeams)
        )
      );
    }


    let tickets = [];
    Observable.forkJoin(obs).subscribe(
      r => r.map((r1: any) => (tickets = [...tickets, ...r1])),
      null,
      () => {
        tickets.map(r => {
          r.number = r.number ? this.pad(r.number, 3, 0) : null;

          if (r.mustStartsAt) r.ms = moment(r.mustStartsAt.toDate()).diff(moment().toDate(), 'minutes');
          if (r.mustAttendsAt) r.ma = moment(r.mustAttendsAt.toDate()).diff(moment().toDate(), 'minutes');
          return r;
        });
        this.tickets.all = tickets.filter(r =>
          tickets.some(r1 => r1.id === r.id)
        ).map(a => {
          a.previa = a.message.replace(/<\/?[^>]+(>|$)/g, " ").replace(new RegExp("&nbsp;", "g"), " ");
          return a;
        });
        this.tickets.filtered = this.tickets.all.filter(
          r => r && r.status !== `Finalizado`
        );
        this.stats.all = this.tickets.filtered.length;
        this.stats.expired = this.tickets.all.filter(
          r => this.checkIfIsLate(r)
        ).length;
        this.stats.forwardedByMe = this.tickets.all.filter(
          r => r && r.forwardedBy && r.forwardedBy.id === this.currentUser.id
        ).length;
        this.stats.finished = this.tickets.all.filter(
          r => r && r.status === `Finalizado`
        ).length;
        this.stats.expiring = this.tickets.all.filter(
          r => r && r.expire < 300 && r.expire > 0 && r.status === "Novo"
        ).length;
        this.tickets.filtered.sort((b, a) => {
          if (a.number && b.number)
            return a.number.toString().localeCompare(b.number.toString());
        });
        this.loadingTickets = false;
      }
    );
  }


  readTicket(ticket) {
    const modalRef = this.modalService.open(TicketComponent, {
      windowClass: "lg transparent animated fadeIn faster",
      centered: true
    });

    modalRef.componentInstance.ticketSelected = ticket;

    modalRef.result.then(r => this.loadTickets());
  }

  initials(text) {
    let i;
    i = text
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "")
      .substr(0, 2);
    i = i + text.substr(2 - i.length, 2 - i.length);
    return i;
  }

  open(content) {
    this.modalRef = this.modalService.open(content, {
      windowClass: "modal-right-bottom"
    });
    this.modalRef.result.then(a => {
      this.loadTickets();
    })
  }

  add() {
    if (
      !this.newTicket.subject ||
      !this.newTicket.teamRequested ||
      !this.newTicket.message
    )
      return;
    const self = this;
    const promises = [];
    this.files.map(r => {
      promises.push(this.upload(r))
    });
    if (promises.length > 0) {
      this.loading = true;
      Promise.all(promises).then((r: []) => {
        this.newTicket.images = r;
        go(self);
      }).finally(() =>
        this.loading = false
      );
    }
    else go(self);

    function go(self) {
      self.loading = true;
      self.newTicket.priority = self.newTicket.priority || "Baixa";
      const deadline = self.newTicket.teamRequested.deadline || self.defaultTimes;

      const mustAttendsAt = (!self.newTicket.priority || self.newTicket.priority == "Baixa") ? deadline.toAttendsLow : (self.newTicket.priority == "Media") ? deadline.toAttendsMedium : deadline.toAttendsHigh;
      self.newTicket.mustAttendsAt = moment().add(mustAttendsAt || 24, 'hours').toDate();
      self.newTicket.mustStartsAt = moment().add(deadline.toStarts || 4, 'hours').toDate();
      self.newTicket.mustEndsAt = moment().add(deadline.toStarts || 4, 'hours').toDate();

      self.newTicket.teamRequested = {
        id: self.newTicket.teamRequested.id,
        name: self.newTicket.teamRequested.name
      };
      self.newTicket.requester = {
        id: self.currentUser.id,
        name: self.currentUser.name
      };
      self.fs.add("/modules/observatory/tickets", self.newTicket).then(res => {
        console.log(res);
        self.notificationService.sendtag(res.id, true);
        self.loadTickets();
        self.loading = false;
        self.quickModal = { show: false, minimized: false };

        self.notificationService.sendOneSignal(
          self.currentUser.name + " abriu um chamado pra você.",
          [self.newTicket.teamRequested.id], 'SIN', 'sin'
        );
        self.newTicket = new Ticket();
        self.files = [];
      });
    }
  }

  onSubscribe() {
    this.onesignal.subscribe();
  }

  onUnSubscribe() {
    this.onesignal.unsubscribe();
  }

  pad(n, width, z?) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
}
