import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  SendVerificationMail() {
    //  return this.afAuth.currentUser.sendEmailVerification()
  }


  getCurrentUser() {
    if (localStorage.getItem(btoa("user"))) {
      return JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem(btoa("user")),
          environment.storageKey
        ).toString(CryptoJS.enc.Utf8)
      );
    }
    return null;
  }

}
