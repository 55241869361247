import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { Injectable, LOCALE_ID, NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgbDateFRParserFormatter } from "@app/_helpers/NgbDateFRParserFormater";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { DataTablesModule } from "angular-datatables";
import { AgWordCloudModule } from "angular4-word-cloud";
import * as Hammer from "hammerjs";
import { RecaptchaModule } from "ng-recaptcha";
import { NgxCurrencyModule } from 'ngx-currency';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from "ngx-loading";
import { NgxMaskModule } from "ngx-mask";
import { MomentModule } from "ngx-moment";
import { NgxOneSignalModule } from "ngx-onesignal";
import { NgScrollbarModule } from "ngx-scrollbar";
import { SortablejsModule } from "ngx-sortablejs";
import { ToastrModule } from "ngx-toastr";
import { environment } from "../environments/environment";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from "./app.component";
import { DashboardModule } from "./dashboard/dashboard.module";
import { FilterPipe } from "./_services/filter.pipe";
;
;
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false },
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL }
  };
}

registerLocaleData(localePt, "pt");

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    MomentModule,
    NgScrollbarModule,
    AgWordCloudModule.forRoot(),
    NgbModule,
    CommonModule,
    ToastrModule.forRoot({ positionClass: "toast-top-center" }),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebases),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    SortablejsModule.forRoot({ animation: 150 }),
    DashboardModule,
    NgxMaskModule.forRoot(),
    NgScrollbarModule,
    RecaptchaModule,
    DataTablesModule,
    RouterModule,
    NgxCurrencyModule,
    MatPasswordStrengthModule.forRoot(),
    NgxChartsModule,
    NgxOneSignalModule.forRoot({
      appId: environment.oneSignal.appId,
      allowLocalhostAsSecureOrigin: true,
      autoRegister: false,
      notifyButton: {
        enabled: false
      }
    }),
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true,
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: "rgba(9, 30, 66, 0.24)",
      primaryColour: "#ffffff",
      secondaryColour: "#ccc",
      tertiaryColour: "#ffffff"
    }),
    AppRoutingModule,
    ServiceWorkerModule.register("OneSignalSDKWorker.js", { enabled: true })
  ],
  declarations: [AppComponent, FilterPipe],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
