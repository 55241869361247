import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LightboxModule } from 'ngx-lightbox';
import { NgxLoadingModule } from "ngx-loading";
import { MomentModule } from "ngx-moment";
import { NgScrollbarModule } from "ngx-scrollbar";
import { AdminObservatoryComponent } from './admin-observatory/admin-observatory.component';
import { TimesComponent } from './admin-observatory/deadline/deadline.component';
import { ObservatoryComponent } from "./observatory.component";
import { TicketComponent } from "./tickets/ticket/ticket.component";


@NgModule({
  declarations: [ObservatoryComponent, TicketComponent, AdminObservatoryComponent, TimesComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgOptionHighlightModule,
    NgbTooltipModule,
    CKEditorModule,
    LightboxModule,
    DataTablesModule,
    NgxDropzoneModule,
    NgxLoadingModule,
    FormsModule,
    MomentModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    NgSelectModule
  ], entryComponents: [TicketComponent]
})
export class ObservatoryModule { }
