import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SidebarComponent } from "@app/_components";
import { NavbarComponent } from "@app/_components/navbar.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgClockPickerLibModule } from "ng-clock-picker-lib";
import { NgxLoadingModule } from "ngx-loading";
import { MomentModule } from "ngx-moment";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DashboardComponent } from "./dashboard.component";
import { HomeComponent } from "./home/home.component";
import { ObservatoryModule } from "./observatory/observatory.module";

@NgModule({
  declarations: [
    DashboardComponent,
    SidebarComponent,
    NavbarComponent,
    HomeComponent
  ],
  exports: [SidebarComponent, NavbarComponent, DashboardComponent],
  imports: [
    CommonModule,
    RouterModule,
    ObservatoryModule,
    NgScrollbarModule,
    NgSelectModule,
    NgxLoadingModule,
    NgClockPickerLibModule,
    NgbModule,
    MomentModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  entryComponents: []
})
export class DashboardModule { }
