<ngx-loading [show]="loading"></ngx-loading>
<div class="row">
  <div class="col-7 ">
    <section class="h-100 p-5" style="padding-left: 1.5rem;">
      <header
        class="left-header"
        style="
          color: #ebf0f7;
        ">
        <div class="row">
          <div class="col">
            <h1 class="title" data-testid="title-description">
              <strong class="title-description">Detalhes do chamado #{{ ticketSelected?.number }}</strong><a
                class="font-bold"
                id="edit-card-title"
                tabindex="0">{{ ticketSelected?.subject }}</a>
            </h1>
          </div>
          <div class="col-auto mb-n4">
            <small class="badge badge-soft-primary p-2" *ngIf="ticketSelected?.status">
              <i class="fal fa-adjust"></i>
              Status: <span>{{ ticketSelected?.status }}</span> </small>
            <br>
            <small class="badge badge-soft-success p-2" *ngIf="ticketSelected?.type">
              <i class="fal fa-adjust"></i>
              Situação <span
                style="text-transform: lowercase;">{{ ticketSelected?.type }}</span> </small>
            <br>
            <small class="badge badge-soft-danger p-2" *ngIf="ticketSelected?.priority">
              <i class="fal fa-adjust"></i>
              Prioridade <span
                style="text-transform: lowercase;">{{ ticketSelected?.priority }}</span> </small>
          </div>
        </div>
        <div>
          <p class="creator-date"> <i class="fal fa-calendar-plus"></i>
            Criado por <u>{{ ticketSelected?.requester?.name }}</u> {{ ticketSelected?.createdAt.toDate() | amTimeAgo }}


          </p>
        </div>
      </header>

      <div class="card shadow-none mb-2 px-3 py-1">
        <header class="header-box">
          <h2>Mensagem de {{ ticketSelected?.requester?.name }}</h2>
        </header>
        <span [innerHTML]="ticketSelected?.message"></span>
        <br>
        <br>
      </div>

      <div class="card px-3 py-2  shadow-none" *ngIf="ticketSelected?.images?.length>0">
        <header class="header-box">
          <h2>Anexos</h2>
        </header>
        <div class="d-flex">
          <img [src]="image" *ngFor="let image of ticketSelected?.images; let i=index" class="thumb"
            (click)="open(i)" />
        </div>
      </div>

      <div class=" px-3 py-2  shadow-none" *ngIf="!readOnly || ticketSelected?.teamRequested">
        <header class="header-box">
          <h2>Setor</h2>
        </header>
        <a class="btn-pipefy" [ngClass]="{'bg-white': readOnly  || ticketSelected?.status=='Finalizado'}"
          (click)="(!readOnly && ticketSelected?.status!='Finalizado')? prepareForward() : null"
          tabindex="0">
          <i class="fad fa-bezier-curve"></i> &nbsp;&nbsp;
          <span [ngClass]="{'label-forward': !ticketSelected?.teamRequested}">
            {{ ticketSelected?.teamRequested?.name || 'Encaminhar para setor' }}</span></a>
        <small *ngIf="ticketSelected?.forwardedBy && ticketSelected?.teamRequested">
          <br>
          Encaminhado por:
          {{ ticketSelected?.forwardedBy?.name }}</small>
      </div>

      <div class=" px-3 py-2 shadow-none" *ngIf="!readOnly || ticketSelected?.assignedTo">
        <header class="header-box">
          <h2>Responsável</h2>
        </header>
        <a class="btn-pipefy" [ngClass]="{'bg-white': readOnly || ticketSelected?.status=='Finalizado'}"
          (click)="(!readOnly && ticketSelected?.status!='Finalizado')? prepareForward(1) : null"
          tabindex="0">
          <i class="fad fa-clipboard-user"></i> &nbsp;&nbsp;
          <span [ngClass]="{'label-forward': !ticketSelected?.assignedTo}">
            {{ ticketSelected?.assignedTo?.name || 'Encaminhar para responsável' }}</span>
        </a>

        <small *ngIf="ticketSelected?.forwardedBy && ticketSelected?.assignedTo"><br> Encaminhado por:
          {{ ticketSelected?.forwardedBy?.name }}</small>
      </div>
      <hr>

      <p class="creator-date">
        <span *ngIf="ticketSelected?.attendant"> <br><i class="fal fa-calendar-check"></i>
          Atendimento iniciado por
          <u>{{ ticketSelected?.attendant }}</u> <span *ngIf="ticketSelected?.startedAt">
            {{ ticketSelected?.startedAt.toDate() | amTimeAgo }}</span>
        </span>

        <span *ngIf="ticketSelected?.attendedAt">
          <br>
          <i class="fal fa-calendar-check"></i> Marcado como atendido por <u>{{ ticketSelected?.attendant }}</u>
          {{ ticketSelected?.attendedAt.toDate() | amTimeAgo }}
        </span>

        <span
          *ngIf="ticketSelected?.requester?.id!==currentUser.id&& ticketSelected?.status=='Atendido'">
          <br>
          <span *ngIf="">
            Aguardando {{ticketSelected?.requester?.name}} finalizar o chamado.
          </span>
        </span>

        <span *ngIf="ticketSelected?.finalizedAt"> <br><i class="fal fa-calendar-check"></i>

          Finalizado
          {{ ticketSelected?.finalizedAt.toDate() | amTimeAgo }}
        </span>

        <div class=" text-center px-3 py-2  shadow-none" *ngIf="!readOnly">
          <a class="btn btn-success text-white btn-md"
            *ngIf="ticketSelected?.requester?.id!==currentUser.id&&ticketSelected?.status=='Em andamento'"
            (click)="attendTicket()">
            <i class="fad fa-check-circle"></i> &nbsp;&nbsp; Marcar como atendido
          </a>
          <div *ngIf="ticketSelected?.status=='Atendido'|| ticketSelected?.status=='Encerrado'">

            <div class="text-center">
              <a class="btn btn-dark text-center text-white btn-md"
                *ngIf="ticketSelected?.requester?.id===currentUser.id"
                (click)="closeTicket()">
                <i class="fad fa-inbox"></i> &nbsp;&nbsp; Fechar chamado
              </a>
            </div>

          </div>
        </div>
    </section>
  </div>
  <div class="col-5 py-4">
    <button class="btn primary text-white btn-lg" style=" position: absolute;
    margin-top: -3em;
    right: 1em;" (click)="activeModal.close()">
      <i class="fa fa-2x fa-window-close"></i>
    </button>
    <div class="card right-side">
      <div class="body">
        <div class="activities" style="background-color: #ffffff;">
          <h3 class="title-tabs" title="Atividades">
            <span>Comentários</span>
          </h3>
          <div
            class="footer-text-area add-comment-form mousetrap"
            data-gtm-vis-first-on-screen-7535347_253="7165045"
            name="form-0">
            <div class="area-comment " *ngIf="!readOnly" (click)="areaComment.focus()"
              [ngClass]="{'active': areaCommentActive === true}">
              <textarea #areaComment style="white-space: pre-line"
                (keydown)="calculateSizeTextarea(areaComment.value,$event)"
                [style.height.px]="sizeTextarea"
                (focus)="[areaCommentActive=true, calculateSizeTextarea(areaComment.value,$event)]"
                (click)="[areaCommentActive=true, calculateSizeTextarea(areaComment.value,$event)]"
                (blur)="[areaCommentActive=false]"
                [(ngModel)]="ticketSelected.newComment"
                class="input comment-box-text-input"
                placeholder="Escreva um comentário "></textarea>

              <button
                type="submit"
                title="Publicar"
                id="card-phase-form-comment-text-area-submit-btn"
                tabindex="0"
                (click)="addComment()"
                class="btn-sm "
                [ngClass]="{disabled: areaComment?.value?.length===0, 'btn-primary': areaComment?.value?.length>0}"
                [disabled]="areaComment?.value?.length===0">
                Publicar
              </button>
            </div>
          </div>
          <div
            class=" comments"
            *ngIf="
            !ticketSelected?.comments ||
            ticketSelected?.comments?.length === 0
          ">
            <div class="empty-box">
              <span class="fal fa-comments"></span>
              <h2 *ngIf="ticketSelected?.requester?.id===currentUser.id">Adicione mais informações à sua requisição.
              </h2>
              <h2 *ngIf="ticketSelected?.requester?.id!==currentUser.id">Insira um comentário para iniciar o atendimento
              </h2>
            </div>
          </div>
          <ng-scrollbar [visibility]="'hover'"
            style="max-height:45vh;  "
            *ngIf="
            ticketSelected?.comments && ticketSelected?.comments?.length > 0
          ">
            <div class="activities pb-5" data-testid="card-activities">
              <div class="activities-item activities-color-blue " *ngFor="let comment of ticketSelected?.comments">
                <span class="action-type p-2 ">
                  <i class="fal fa-comment-alt"></i></span>
                <p> <b> {{ comment?.user?.name }} </b> <span class="activities-date" *ngIf=" comment?.createdAt">{{
                  comment?.createdAt?.toDate() | amTimeAgo
                }}</span> </p>

                <div
                  data-testid="activity-detail-card-comment"
                  class="area-box" [innerHTML]="comment?.message">
                </div>
              </div>
            </div>
          </ng-scrollbar>
        </div>
      </div>
    </div>
  </div>
</div>