import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire/database';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AuthenticationService } from '@app/_services';
import { CacheService } from "@app/_services/cache.service";
import { firestoreService } from '@app/_services/firestore.service';
import { NotificationService } from '@app/_services/notification.service';
import { UserService } from '@app/_services/user.service';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { OneSignalService } from 'ngx-onesignal';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: "app-navbar",
  templateUrl: "navbar.component.html"
})
export class NavbarComponent implements OnInit {
  currentUser: { id: null, name: null, teams: [] };
  currentModule;
  elem;
  fullscreen = false;
  notifications = [];
  hasNewNotification = false;
  title;
  theme;
  notificationsSeen = [];

  loading: { modules: false, notifications: false }


  modules;

  constructor(
    private UserService: UserService,
    private toastr: ToastrService,
    private afdb: AngularFireDatabase,
    private cs: CacheService,
    public readonly onesignal: OneSignalService,
    private ns: NotificationService,
    private authenticationService: AuthenticationService,
    private fs: firestoreService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    @Inject(DOCUMENT) private document: any
  ) {
    this.currentUser = this.UserService.getCurrentUser();
    this.notificationsSeen = JSON.parse(localStorage.getItem('notificationsSeen') || `[]`);

  }
  ngOnInit() {
    this.fs.colWithIds$('/infrastructure/access/permissions/', ref => ref.where('members', 'array-contains', this.currentUser.id)).subscribe(r => this.modules = r.filter(a => a.is_web));
    this.theme = localStorage.getItem("data-ma-theme") || `blue`;

    this.synchronizeInfo();
    this.elem = document.documentElement;
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      })
      .filter(route => route.outlet === "primary")
      .mergeMap(route => route.data)
      .subscribe(event => (this.title = event["title"]));
    this.checkCurrentModule(this.router.url);
    this.loadNotifications();
  }

  synchronizeInfo() {
    if (!localStorage.getItem('lastSyncronizeInfo') || moment(new Date()).diff(new Date(localStorage.getItem('lastSyncronizeInfo')), "minutes") > 20) {
      this.ns.loadTags();
      const self = this;
      (<any>window).ngxOnesignal = this.onesignal;
      setTimeout(() => {
        (<any>window).ngxOnesignal.push([
          "getUserId",
          function (result) {
            localStorage.setItem('lastSyncronizeInfo', new Date() + ``);
            console.log((<any>window).ngxOnesignal);
            const oneSignalInfo: any = {
              isSupported: (<any>window).ngxOnesignal.isSupported,
              isSubscribe: (<any>window).ngxOnesignal.isSubscribe,
              isInitialized: (<any>window).ngxOnesignal.isInitialized,
              isOptedOut: (<any>window).ngxOnesignal.isOptedOut
            }
            if (result) oneSignalInfo.playerId = result;
            self.fs.update('/infrastructure/access/users/' + self.currentUser.id, { oneSignalInfo: oneSignalInfo, lastLoginWeb: new Date(), platformVersion: environment.version });
          }
        ]);
      }, 5000)
    }
  }

  clearCache() {
    this.cs.clearCache();
  }

  checkCurrentModule(url) {
    this.currentModule = url.includes("/pesquisas")
      ? "Pesquisa de satisfação"
      : url.includes("/sin")
        ? "SIN"
        : url.includes("/fastfood")
          ? "Lanchonete"
          : url.includes("/gestao-sin")
            ? "Gestão SIN"
            : url.includes("/fornecimento")
              ? "Controle de Entregas"
              : url.includes("/admin")
                ? "Administraçao Nutribem"
                : "Portal Nutribem";
  }

  changeTheme(theme) {
    this.theme = theme;
    localStorage.setItem("data-ma-theme", this.theme);
  }

  toggleFullscreen() {
    if (!this.fullscreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
        this.fullscreen = true;
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
        this.fullscreen = true;
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
        this.fullscreen = true;
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
        this.fullscreen = true;
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
        this.fullscreen = false;
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
        this.fullscreen = false;
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
        this.fullscreen = false;
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
        this.fullscreen = false;
      }
    }
  }

  navigate(route) {
    this.checkCurrentModule(route);
    this.router.navigate([route]);
  }

  loadNotifications() {
    const querys = [];

    this.currentUser.teams.map((t: any) => {
      querys.push(this.afdb.list('notifications', ref => ref.orderByChild('tag').equalTo(t.id).limitToFirst(10)).snapshotChanges().pipe(
        take(1),
        map((changes: any) =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      ));
    });

    querys.push(this.afdb.list('notifications', ref => ref.orderByChild('tag').equalTo(this.currentUser.id).limitToFirst(10)).snapshotChanges().pipe(
      take(1),
      map((changes: any) =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ));

    Observable.forkJoin(querys).subscribe(
      r => {
        r.map((r1: any) => (this.notifications = [...this.notifications, ...r1]));
        this.notifications = (this.notifications && this.notifications.length) ? this.notifications.sort((b, a) => (a.createdAt + '').localeCompare(b.createdAt + '')).map(n => n = (n && n.createdAt) ? { ...n, createdAt: new Date(n.createdAt) } : n) : this.notifications;
        this.notifications.map(r => r.seen = this.notificationsSeen.includes(r.key));
        this.checkIfHasNew();
        return r;
      }, null, () => {
        // firebase.database().goOffline();
      });
  }
  readNotification(notification) {
    notification.seen = true;
    if (!this.notificationsSeen.includes(notification.key)) {
      this.notificationsSeen.push(notification.key);
      localStorage.setItem('notificationsSeen', JSON.stringify(this.notificationsSeen));
      this.checkIfHasNew();
    }
  }

  checkIfHasNew() {
    this.hasNewNotification = this.notifications.some(r => !r.seen);
  }

  logout() {
    this.authenticationService.SignOut();
  }

  goHome() {
    let url = this.router.url.substring(this.router.url.indexOf("/") + 1);
    url = url.substr(0, url.indexOf("/"));
    if (!url) return;
    this.router.navigate([url]);
  }

  nthIndex(str, pat, n) {
    var L = str.length,
      i = -1;
    while (n-- && i++ < L) {
      i = str.indexOf(pat, i);
      if (i < 0) break;
    }
    return i;
  }

  testarNotificacoes() { }
}
