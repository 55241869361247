// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: {
    oneSignal: "https://onesignal.com/api/v1/notifications"
  },
  firebases: {
    apiKey: "AIzaSyAu0qkYLr2sbGhDoEo6TZdk7INdlK8WmGk",
    authDomain: "nutribem-dev.firebaseapp.com",
    databaseURL: "https://nutribem-dev.firebaseio.com",
    projectId: "nutribem-dev",
    storageBucket: "nutribem-dev.appspot.com",
    messagingSenderId: "9328563493",
    appId: "1:9328563493:web:9cdbea042a46478fec36cc",
    measurementId: "G-Q3KEQCVV28"
  },
  firebase: {
    apiKey: "AIzaSyBu7cNVXmqnqM9zEhoS6GPUVxaV0EKadTk",
    authDomain: "nutribem-erp.firebaseapp.com",
    databaseURL: "https://nutribem-erp.firebaseio.com",
    projectId: "nutribem-erp",
    storageBucket: "nutribem-erp.appspot.com",
    messagingSenderId: "508004061128",
    appId: "1:508004061128:web:3d3b196d33e8c204e6bafd",
    measurementId: "G-ZEGXC4XGTT"
  },
  recaptchaKey: "6LfPJs0UAAAAAOFrMWQqaPRsMQ-n4vlwuIAdyhUe",
  oneSignal: {
    appId: "b919186b-f797-4349-be1d-80171b391a91",
    restApiKey: "NDdmOTk0MTUtMGM0MS00NzM1LWE3ZWEtMjNiY2ExZWY5YTAy"
  },
  storageKey: "74b0LOWx",
  defaultLogin: {
    username: "camilopinheiro@live.com",
    password: "wcy91um2"
  },
  version: 1
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.,
