import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from '@app/_services/user.service';

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const routerRoles = route.data["roles"] as Array<string>;
    const user = this.userService.getCurrentUser();
    if (user) {
      if (routerRoles && routerRoles.length > 0)
        if (user.profiles)
          return user.profiles.some(r => routerRoles.indexOf(r) >= 0);
        else return false;
      else return user.id ? true : this.vaza();
    } else {
      this.vaza();
    }
  }

  vaza() {
    this.router.navigate(["/acesso"]);
    return false;
  }
}
