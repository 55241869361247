import * as moment from 'moment';


export class Ticket {
  id?: number;
  attendant?: string;
  comments?: any;
  subject: string;
  message: string = '';
  forwardedBy: { id: null, name: null };
  assignedTo?: { id: null, name: null };
  status: "Novo" | "Em andamento" | "Atendido" | "Finalizado" = "Novo";
  images?: [] = [];
  requester: { id: null, name: null };
  teamRequested: { id: null, name: null };
  number?: number;
  type: "Nova" | "Recorrente" = "Nova";
  priority: "Alta" | "Media" | "Baixa";
  mustAttendsAt?: any = moment().add(3, 'days').toDate();
  mustStartsAt?: any = moment().add(4, 'hours').toDate();
  createdAt?: any;
  startedAt?: any;
  finalizedAt?: any;
  attendedAt?: any;
  updatedAt?: any;
  newComment: any;
}