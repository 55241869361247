import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import Swal from 'sweetalert2';
import { firestoreService } from './firestore.service';

@Injectable({
  providedIn: "root"
})
export class SharedService {
  periodReport = new BehaviorSubject<any>(7);
  constructor(private fs: firestoreService) { }

  setPeriodReport(period) {
    this.periodReport.next(period);
  }

  getPeriodReport() {
    return this.periodReport.asObservable();
  }

  getInitials(text, array?) {
    if (!text) return;
    text = text.trim();
    let i = text
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "")
      .substr(0, 2);
    i = i + text.replace(/\s/g, "").substr(2 - i.length, 2 - i.length);
    if (!array) return i;
    let cont1 = 1;
    let cont2 = 1;
    while (
      array.filter(r => r && r.toUpperCase() == i.toUpperCase()).length > 0
    ) {
      i = text.substr(Math.floor(cont2 / 10), 1);
      if (cont1 < text.length) i = i + text.replace(/\s/g, "").substr(cont1, 1);
      else {
        i = i + (array.filter(r => r == i).length + (cont2 % 10));
        cont2++;
      }
      cont1++;
    }
    return i;
  }

  delete(item: { name, id }, collection, securityLevel: 0 | 1 | 2, placeholder) {
    let promise;

    if (securityLevel <= 0) {
      return this.fs.delete(collection + `/` + item.id);
    } else {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: "Tem certeza?",
          html: (securityLevel > 1) ? `Para confirmar a exclusão, digite <b><u> ${item.name}</u></b> no campo abaixo` : 'Os dados podem não ser recuperados',
          icon: "warning",
          showLoaderOnConfirm: true,
          inputPlaceholder: placeholder,
          animation: false,
          input: (securityLevel > 1) ? "text" : null,
          showCancelButton: true,
          cancelButtonText: "Desisto",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Remover",
          preConfirm: v => {
            if (securityLevel > 1 && v.trim() !== item.name)
              return Swal.showValidationMessage(`Digite corretamente`);
            else {
              return this.fs
                .delete(collection + `/` + item.id).then(() => resolve('deu')).catch(erro => {
                  Swal.showValidationMessage(`Não foi possível remover`);
                });
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        })
      })
    }
  }
}
