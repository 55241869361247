import { Component, Input, OnInit } from '@angular/core';
import { firestoreService } from '@app/_services/firestore.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-deadline',
  template: `<div class=" pl-3 listview listview--hover">
  <a class="listview__item d-flex justify-content-between"
    (click)="editDeadline('toStarts', ' prazo para iniciar o atendimento')">
    <span>Para iniciar o chamado</span>
    <span class="badge badge-soft-info" *ngIf="deadline?.toStarts">{{deadline?.toStarts}} horas
    </span>
  </a>

  <a class="listview__item d-flex justify-content-between"
    (click)="editDeadline('toAttendsHigh', ' prazo para atender na prioridade alta')">
    <span>Atender - Prioridade alta</span>
    <span class="badge badge-soft-info" *ngIf="deadline?.toAttendsHigh">{{deadline?.toAttendsHigh}} horas
    </span>
  </a>
  <a class="listview__item d-flex justify-content-between"
    (click)="editDeadline('toAttendsMedium', ' prazo para atender na prioridade média')">
    <span>Atender - Prioridade média</span>
    <span class="badge badge-soft-info" *ngIf="deadline?.toAttendsMedium">{{deadline?.toAttendsMedium}} horas
    </span>
  </a>
  <a class="listview__item d-flex justify-content-between"
    (click)="editDeadline('toAttendsLow', ' prazo para atender na prioridade baixa')">
    <span>Atender - Prioridade baixa</span>
    <span class="badge badge-soft-info" *ngIf="deadline?.toAttendsLow">{{deadline?.toAttendsLow}} horas
    </span>
  </a>
</div>`
})
export class TimesComponent implements OnInit {
  @Input() deadline;
  @Input() team;
  constructor(private fs: firestoreService) { }

  ngOnInit(): void {
    this.deadline = (this.team && this.team.deadline) ? this.team.deadline : (this.deadline || {});
  }


  editDeadline(field, label) {
    const self = this;
    Swal.fire({
      title: "Definir " + label,
      input: "text",
      animation: false,
      inputValue: this.deadline[field],
      inputAttributes: { autocapitalize: "off" },
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Salvar",
      showLoaderOnConfirm: true,
      preConfirm: v => {
        if (this.team) this.team.deadline[field] = v;
        this.deadline[field] = v;
        if ((this.team && !this.team.id)) return false;
        const q = (this.team && this.team.id) ?
          self.fs.update(`modules/observatory/teams/${this.team.id}/`, { deadline: this.deadline }) :
          self.fs.update(`modules/observatory/`, { defaultTimes: this.deadline });
        console.log(this.deadline)
        return q.catch(error => {
          if (error.error) {
            Swal.showValidationMessage(` ${error.error.error}`);
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.value !=null ) {
        Swal.fire({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          title: "Alterado com sucesso"
        });
      }
    });
  }

}
