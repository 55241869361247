import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './dashboard/home/home.component';
import { AdminObservatoryComponent } from './dashboard/observatory/admin-observatory/admin-observatory.component';
import { ObservatoryComponent } from './dashboard/observatory/observatory.component';
import { TicketComponent } from './dashboard/observatory/tickets/ticket/ticket.component';
import { AuthGuard } from './_guards';


const appRoutes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {},
    children: [
      {
        path: "home",
        component: HomeComponent,
        data: { title: "Pesquisa de satisfação" }
      },
      {
        path: "pesquisas",
        loadChildren: () => import('app/dashboard/surveys/surveys.module').then(m => m.SurveysModule),
        data: { title: "Pesquisa de satisfação" }
      },
      {
        path: "fastfood",
        loadChildren: () => import('app/dashboard/fastfood/fastfood.module').then(m => m.FastfoodModule),
        data: {
          title: "Lanchonete",
          roles: ["administrator", "coordenator", "supervisor"]
        }
      },
      {
        path: "admin",
        data: { title: "Administração" },
        loadChildren: () => import('app/dashboard/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: "gestao-sin",
        component: AdminObservatoryComponent,
        data: { title: "Gestão SIN" },
        children: [{ path: ":id", component: TicketComponent }]
      },
      {
        path: "sin",
        component: ObservatoryComponent,
        data: { title: "Solicitações Internas Nutribem" },
        children: [{ path: ":id", component: TicketComponent }]
      },
      {
        path: "fornecimento",
        data: { title: "Fornecimento" },
        loadChildren: () => import('app/dashboard/supply/supply.module').then(m => m.SupplyModule),
      }
    ]
  },
  {
    path: "publico",
    loadChildren: () => import('app/publico/publico.module').then(m => m.PublicoModule),
    data: { title: "Login" }
  },
  {
    path: "acesso",
    loadChildren: () => import('app/access/access.module').then(m => m.AccessModule),
    data: { title: "Login" }
  }
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
