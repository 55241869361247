import { Component, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CacheService } from '@app/_services/cache.service';
import { firestoreService } from "@app/_services/firestore.service";
import { NotificationService } from "@app/_services/notification.service";
import { UserService } from '@app/_services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from 'events';
import * as moment from 'moment';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2';
import { Ticket } from '../../../../_models/tickets';

@Component({
  selector: "app-ticket",
  templateUrl: "./ticket.component.html",
  styleUrls: ["./ticket.component.css"]
})
export class TicketComponent implements OnInit {
  @Input() readOnly;
  @Input() ticketSelected: Ticket;
  @Output() loadTickets = new EventEmitter();
  areaCommentActive;
  currentUser;
  loading;
  teams;
  sizeTextarea = 21;
  @ViewChild("lastComment", { static: true }) lastComment: any;

  constructor(
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private fs: firestoreService,
    private notificationService: NotificationService,
    private cs: CacheService,
    private _lightbox: Lightbox,
    private ns: NotificationService,
    private UserService: UserService
  ) {
    this.currentUser = this.UserService.getCurrentUser();
    this.currentUser = {
      id: this.currentUser.id,
      name: this.currentUser.name
    };
  }

  ngAfterViewInit() {
    this.fs.colWithIds$('/modules/observatory/teams').subscribe(r => this.teams = r);
    if (this.lastComment) this.lastComment.nativeElement.focus();
    this.orderComments();
  }

  open(index: number): void {
    this._lightbox.open(this.ticketSelected.images.map((r, i) => {
      const b: any = {};
      b.src = r;
      b.caption = "Anexo " + (i + 1);
      b.thumb = r;
      return b;
    }), index, { wrapAround: true, centerVertically: true });
  }


  ngOnInit() {
    if (this.ticketSelected.comments) this.orderComments();
    console.log(typeof this.ticketSelected.createdAt)
  }

  prepareForward(toUser?) {
    const inputOptions: any = [];
    this.teams.map(item => inputOptions[item.id] = item.name);
    Swal.fire(
      {
        input: 'select',
        inputOptions: inputOptions,
        animation: false,
        showCancelButton: true,
        confirmButtonText: (!toUser) ? 'Encaminhar para o setor' : 'Próximo',
        cancelButtonText: 'Desisto',
        inputPlaceholder: 'Clique para selecionar',
        title: 'Escolha um setor',
        inputValue: '1',
        text: 'O chamado irá sair da sua caixa de entrada ao encaminhar',
      }
    ).then(r => {
      if (!r.value) return;
      let team = this.teams.find(a => a.id === r.value);
      if (!toUser || (!team.members || team.members.length === 0)) {
        this.ticketSelected.teamRequested = { id: team.id, name: team.name };
        this.ticketSelected.assignedTo = null;
        this.ticketSelected.forwardedBy = { id: this.currentUser.id, name: this.currentUser.name };
        this.doForward();
        if (!team.members || team.members.length === 0)
          Swal.fire({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "O setor não tem usuários cadastrados, desta forma, o chamado foi encaminhado ao setor"
          })
      }
      else this.prepareForwardToUser(team.members);
    });
  }

  prepareForwardToUser(members) {
    const inputOptions: any = [];
    members.map(item => inputOptions[item.id] = item.name);
    Swal.fire(
      {
        input: 'select',
        inputOptions: inputOptions,
        showCancelButton: true,
        inputPlaceholder: 'Clique para selecionar',
        animation: false,
        confirmButtonText: 'Encaminhar',
        cancelButtonText: 'Desisto',
        title: 'Selecione o usuário',
        text: 'O chamado irá sair da sua caixa de entrada ao encaminhar',
      }
    ).then(r => {
      if (!r.value) return;
      let member = members.find(a => a.id === r.value);
      if (member) {
        this.ticketSelected.assignedTo = { id: member.id, name: member.name };
        this.ticketSelected.teamRequested = null;
        this.ticketSelected.forwardedBy = { id: this.currentUser.id, name: this.currentUser.name };
      }
      this.doForward();
    });
  }

  doForward() {
    console.log(this.ticketSelected);
    const self = this;
    const target: any = this.ticketSelected.teamRequested || this.ticketSelected.assignedTo;

    Swal.fire({
      title: 'Encaminhar chamado para ' + target.name,
      text: 'Deseja inserir um comentário?',
      inputPlaceholder: 'Ex.: após conclusão, encaminhar para setor x',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Encaminhar',
      cancelButtonText: 'Sair',
      showLoaderOnConfirm: true,
      preConfirm: (motivo) => {
        if (motivo) {
          self.ticketSelected.newComment = motivo;
          self.addComment().then(r => go(this));
        } else go(this);
        function go(self) {


          const clonedTicket = Object.assign(Object.create(self.ticketSelected), self.ticketSelected);
          delete clonedTicket.attendedAt;
          delete clonedTicket.attendant;
          clonedTicket.number = +clonedTicket.number;
          delete clonedTicket.ma;
          delete clonedTicket.previa;
          delete clonedTicket.ms;
          delete clonedTicket.startedAt;
          clonedTicket.updatedAt = new Date();
          clonedTicket.status = 'Novo';
          clonedTicket.mustStartsAt = moment().add(moment(clonedTicket.mustStartsAt.toDate())
            .diff(moment(clonedTicket.createdAt.toDate()), 'minutes') || 4 * 60, 'minutes').toDate();
          clonedTicket.mustEndsAt = moment().add(moment(clonedTicket.mustEndsAt.toDate())
            .diff(moment(clonedTicket.createdAt.toDate()), 'minutes') || 30 * 60, 'minutes').toDate();
          clonedTicket.mustAttendsAt = moment().add(moment(clonedTicket.mustAttendsAt.toDate())
            .diff(moment(clonedTicket.createdAt.toDate()), 'minutes') || 24 * 60, 'minutes').toDate();

          self.loading = true;

          self.notificationService.sendOneSignal(
            self.currentUser.name + " encaminhou um chamado pra você.",
            [(clonedTicket.teamRequested) ? clonedTicket.teamRequested.id : clonedTicket.assignedTo.id], 'SIN', ["sin", self.ticketSelected.id]
          );
          self.notificationService.sendOneSignal(
            self.currentUser.name + " encaminhou seu chamado",
            [clonedTicket.id], 'SIN', ["sin", self.ticketSelected.id]
          );

          return self.fs.doc('/modules/observatory/tickets/' + clonedTicket.id).set({ ...clonedTicket }, { merge: false }).then(r => {
            self.activeModal.close('teste');
            self.loadTickets.emit('true');
            self.loading = false;
            self.toastr.show("Chamado encaminhado para " + target.name, null, {
              positionClass: "toast-top-center"
            });
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  addComment() {
    const comment: any = {
      user: this.currentUser,
      message: this.ticketSelected.newComment
    };
    this.loading = true;

    return new Promise((resolve, reject) => {
      this.ticketSelected.newComment = null;
      this.fs
        .doc$("/modules/observatory/tickets/" + this.ticketSelected.id)
        .subscribe(
          (r: any) => (this.ticketSelected.comments = r.comments || []),
          null,
          () => {
            this.ticketSelected.comments = this.ticketSelected.comments || [];
            this.orderComments();
            this.loading = false;

            const ticketTemp: any = {
              comments: [...this.ticketSelected.comments, { ...comment, createdAt: new Date() }]
            };
            if (this.ticketSelected.status === "Novo" && this.currentUser.id !== this.ticketSelected.requester.id) {
              ticketTemp.status = "Em andamento";
              this.ticketSelected.status = "Em andamento";
              ticketTemp.startedAt = moment().toDate();
              ticketTemp.attendant = this.currentUser.name;
            }

            const self = this;

            this.fs
              .update(
                "/modules/observatory/tickets/" + this.ticketSelected.id + "",
                ticketTemp
              )
              .then(() => {
                if (self.currentUser.id !== self.ticketSelected.requester.id)
                  this.ns.sendOneSignal(
                    this.currentUser.name + " comentou seu chamado",
                    [this.ticketSelected.id], 'SIN', ["sin", this.ticketSelected.id]
                  );
                this.calculateSizeTextarea("", null);
                this.fs
                  .doc$("/modules/observatory/tickets/" + this.ticketSelected.id)
                  .subscribe((a: any) => this.ticketSelected.comments = (a && a.comments) ? a.comments : [], null, () => {
                    this.orderComments();
                    resolve(true);
                    this.toastr.show("Comentario adicionado", null, {
                      positionClass: "toast-top-center"
                    });
                    this.ticketSelected.newComment = null;
                  });
              });
          }
        );
    });
  }

  orderComments() {
    if (!this.ticketSelected.comments || this.ticketSelected.comments.length == 0) return;
    this.ticketSelected.comments.sort(
      (b: any, a: any) =>
        a.createdAt &&
        b.createdAt &&
        a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime()
    );
  }

  calculateSizeTextarea(text, event) {
    this.sizeTextarea = (text.split(/\r\n|\r|\n/).length || 1) * 25;
    this.sizeTextarea += Math.floor(text.length / 43) * 25;
    //  this.sizeTextarea = this.sizeTextarea > 30 ? this.sizeTextarea : 30;
    if (event && event.key === "Enter") this.sizeTextarea += 25;
  }
  attendTicket() {
    this.loading = true;

    this.fs.update('/modules/observatory/tickets/' + this.ticketSelected.id, { status: "Atendido", attendedAt: moment().toDate() }).then(r => {
      this.activeModal.close();
      this.loading = false;

      Swal.fire({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: "Chamado marcado como atendido"
      })
    });
  }

  closeTicket() {
    this.loading = true;

    this.fs.update('/modules/observatory/tickets/' + this.ticketSelected.id, { status: "Finalizado", finalizedAt: moment().toDate() }).then(r => {
      this.activeModal.close();
      this.loading = false;

      Swal.fire({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: "Chamado finalizado"
      })
    });
  }
}
