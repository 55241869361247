<div class="container-fluid" hidden>
  <div class="row justify-content-center mt-5">
    <div class="col-3">
      qwdf
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Controle de ações</h4>
          <h6 class="card-subtitle">Um aplicativo massa</h6>
          <table class="table mb-0">
            <thead>
              <tr>
                <th>Data</th>
                <th>Papel </th>
                <th>Operação</th>
                <th>Qtde</th>
                <th>VPA</th>
                <th>Custo</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dt of data">
                <td>{{dt.date}}</td>
                <td>{{dt.ticker}} </td>
                <td>{{dt.operation}}</td>
                <td>{{dt.qtde}}</td>
                <td>{{dt.vpa | currency:'BRL':'symbol-narrow':'1.2-2'}}</td>
                <td>{{dt.cust | currency:'BRL':'symbol-narrow':'1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card">
        <div class="card-body">
          Daytrades
        </div>
      </div>
    </div>
  </div>
</div>