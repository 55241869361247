export const data=[
  {"date":	"16/07/2019",	"ticker":	"LIGT3",	"operation":	"C"	,"qtde":	10,	"vpa":	19.46},
{"date":	"16/07/2019",	"ticker":	"GRND3",	"operation":	"C"	,"qtde":	100,	"vpa":	7.92},
{"date":	"16/07/2019",	"ticker":	"SQIA3",	"operation":	"C"	,"qtde":	1,	"vpa":	47.88},
{"date":	"17/07/2019",	"ticker":	"GOAU4",	"operation":	"C"	,"qtde":	3,	"vpa":	6.98},
{"date":	"17/07/2019",	"ticker":	"GOAU4",	"operation":	"C"	,"qtde":	7,	"vpa":	6.99},
{"date":	"17/07/2019",	"ticker":	"JHSF3",	"operation":	"C"	,"qtde":	70,	"vpa":	3.49},
{"date":	"17/07/2019",	"ticker":	"B3SA3",	"operation":	"C"	,"qtde":	2,	"vpa":	41.49},
{"date":	"17/07/2019",	"ticker":	"BBDC4",	"operation":	"C"	,"qtde":	2,	"vpa":	37.98},
{"date":	"17/07/2019",	"ticker":	"FLMA11",	"operation":	"C"	,"qtde":	110,	"vpa":	3.91},
{"date":	"17/07/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	10,	"vpa":	7.26},
{"date":	"17/07/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	30,	"vpa":	7.27},
{"date":	"17/07/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	100,	"vpa":	7.26},
{"date":	"17/07/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	50,	"vpa":	7.24},
{"date":	"17/07/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	10,	"vpa":	7.14},
{"date":	"17/07/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	10,	"vpa":	25.53},
{"date":	"17/07/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	100,	"vpa":	7.05},
{"date":	"17/07/2019",	"ticker":	"CIEL3",	"operation":	"C"	,"qtde":	100,	"vpa":	6.90},
{"date":	"17/07/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	12.89},
{"date":	"17/07/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	50,	"vpa":	7.25},
{"date":	"17/07/2019",	"ticker":	"JHSF3",	"operation":	"V"	,"qtde":	51,	"vpa":	3.51},
{"date":	"17/07/2019",	"ticker":	"CIEL3",	"operation":	"V"	,"qtde":	100,	"vpa":	6.90},
{"date":	"18/07/2019",	"ticker":	"JHSF3",	"operation":	"C"	,"qtde":	1,	"vpa":	3.61},
{"date":	"18/07/2019",	"ticker":	"EVEN3",	"operation":	"C"	,"qtde":	5,	"vpa":	9.49},
{"date":	"18/07/2019",	"ticker":	"JHSF3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.60},
{"date":	"18/07/2019",	"ticker":	"JHSF3",	"operation":	"C"	,"qtde":	1,	"vpa":	3.61},
{"date":	"18/07/2019",	"ticker":	"MRVE3",	"operation":	"C"	,"qtde":	7,	"vpa":	19.76},
{"date":	"18/07/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	5,	"vpa":	12.91},
{"date":	"18/07/2019",	"ticker":	"TIET11",	"operation":	"C"	,"qtde":	10,	"vpa":	12.74},
{"date":	"18/07/2019",	"ticker":	"B3SA3",	"operation":	"V"	,"qtde":	2,	"vpa":	41.25},
{"date":	"19/07/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	50,	"vpa":	7.26},
{"date":	"19/07/2019",	"ticker":	"SQIA3",	"operation":	"C"	,"qtde":	2,	"vpa":	56.80},
{"date":	"19/07/2019",	"ticker":	"LOGG3",	"operation":	"C"	,"qtde":	5,	"vpa":	23.25},
{"date":	"19/07/2019",	"ticker":	"BBDC4",	"operation":	"C"	,"qtde":	1,	"vpa":	38.12},
{"date":	"19/07/2019",	"ticker":	"SLED4",	"operation":	"C"	,"qtde":	100,	"vpa":	1.50},
{"date":	"19/07/2019",	"ticker":	"SLED4",	"operation":	"C"	,"qtde":	100,	"vpa":	1.55},
{"date":	"19/07/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.15},
{"date":	"19/07/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	50,	"vpa":	7.15},
{"date":	"19/07/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	50,	"vpa":	7.42},
{"date":	"22/07/2019",	"ticker":	"SQIA3",	"operation":	"C"	,"qtde":	1,	"vpa":	59.35},
{"date":	"22/07/2019",	"ticker":	"SQIA3",	"operation":	"C"	,"qtde":	1,	"vpa":	59.82},
{"date":	"22/07/2019",	"ticker":	"SLED4",	"operation":	"C"	,"qtde":	100,	"vpa":	1.43},
{"date":	"22/07/2019",	"ticker":	"LOGG3",	"operation":	"C"	,"qtde":	5,	"vpa":	23.78},
{"date":	"22/07/2019",	"ticker":	"SQIA3",	"operation":	"V"	,"qtde":	1,	"vpa":	56.00},
{"date":	"22/07/2019",	"ticker":	"SLED4",	"operation":	"V"	,"qtde":	100,	"vpa":	1.42},
{"date":	"24/07/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	24.95},
{"date":	"24/07/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	24.98},
{"date":	"24/07/2019",	"ticker":	"SUZB3",	"operation":	"C"	,"qtde":	10,	"vpa":	32.01},
{"date":	"24/07/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	60,	"vpa":	7.16},
{"date":	"24/07/2019",	"ticker":	"FLMA11",	"operation":	"C"	,"qtde":	100,	"vpa":	4.30},
{"date":	"24/07/2019",	"ticker":	"FLMA11",	"operation":	"C"	,"qtde":	40,	"vpa":	4.20},
{"date":	"24/07/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	10,	"vpa":	26.14},
{"date":	"24/07/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	5,	"vpa":	13.45},
{"date":	"24/07/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	100,	"vpa":	7.31},
{"date":	"24/07/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	50,	"vpa":	7.16},
{"date":	"24/07/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	50,	"vpa":	7.16},
{"date":	"24/07/2019",	"ticker":	"FLMA11",	"operation":	"V"	,"qtde":	100,	"vpa":	4.35},
{"date":	"24/07/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	30,	"vpa":	7.16},
{"date":	"24/07/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	30,	"vpa":	7.15},
{"date":	"24/07/2019",	"ticker":	"SLED4",	"operation":	"V"	,"qtde":	26,	"vpa":	1.49},
{"date":	"24/07/2019",	"ticker":	"SLED4",	"operation":	"V"	,"qtde":	100,	"vpa":	1.49},
{"date":	"25/07/2019",	"ticker":	"ABEV3",	"operation":	"C"	,"qtde":	10,	"vpa":	19.33},
{"date":	"25/07/2019",	"ticker":	"LIGT3",	"operation":	"C"	,"qtde":	1,	"vpa":	18.79},
{"date":	"25/07/2019",	"ticker":	"ECOR3",	"operation":	"C"	,"qtde":	20,	"vpa":	10.92},
{"date":	"25/07/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	5,	"vpa":	24.78},
{"date":	"25/07/2019",	"ticker":	"MDIA3",	"operation":	"C"	,"qtde":	10,	"vpa":	38.85},
{"date":	"25/07/2019",	"ticker":	"ECOR3",	"operation":	"C"	,"qtde":	30,	"vpa":	11.09},
{"date":	"25/07/2019",	"ticker":	"SUZB3",	"operation":	"C"	,"qtde":	5,	"vpa":	32.37},
{"date":	"25/07/2019",	"ticker":	"SQIA3",	"operation":	"V"	,"qtde":	4,	"vpa":	52.00},
{"date":	"26/07/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	10,	"vpa":	24.80},
{"date":	"26/07/2019",	"ticker":	"SUZB3",	"operation":	"C"	,"qtde":	5,	"vpa":	31.20},
{"date":	"26/07/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	10,	"vpa":	25.34},
{"date":	"26/07/2019",	"ticker":	"TIET11",	"operation":	"C"	,"qtde":	10,	"vpa":	12.57},
{"date":	"26/07/2019",	"ticker":	"QUAL3",	"operation":	"C"	,"qtde":	10,	"vpa":	22.30},
{"date":	"26/07/2019",	"ticker":	"EVEN3",	"operation":	"V"	,"qtde":	5,	"vpa":	9.37},
{"date":	"26/07/2019",	"ticker":	"MRVE3",	"operation":	"V"	,"qtde":	7,	"vpa":	19.05},
{"date":	"30/07/2019",	"ticker":	"TPIS3",	"operation":	"C"	,"qtde":	39,	"vpa":	1.72},
{"date":	"30/07/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	20,	"vpa":	13.01},
{"date":	"30/07/2019",	"ticker":	"SLED4",	"operation":	"V"	,"qtde":	34,	"vpa":	1.38},
{"date":	"31/07/2019",	"ticker":	"TRPL4",	"operation":	"V"	,"qtde":	10,	"vpa":	24.20},
{"date":	"02/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	12.49},
{"date":	"02/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	10,	"vpa":	7.49},
{"date":	"02/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	10,	"vpa":	7.50},
{"date":	"02/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	10,	"vpa":	7.52},
{"date":	"02/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	10,	"vpa":	7.50},
{"date":	"02/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	10,	"vpa":	7.50},
{"date":	"02/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	10,	"vpa":	7.54},
{"date":	"02/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	10,	"vpa":	7.51},
{"date":	"02/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	10,	"vpa":	7.55},
{"date":	"05/08/2019",	"ticker":	"ABEV3",	"operation":	"C"	,"qtde":	10,	"vpa":	20.17},
{"date":	"05/08/2019",	"ticker":	"JHSF3",	"operation":	"C"	,"qtde":	19,	"vpa":	3.74},
{"date":	"05/08/2019",	"ticker":	"SLCE3",	"operation":	"C"	,"qtde":	30,	"vpa":	18.00},
{"date":	"05/08/2019",	"ticker":	"LIGT3",	"operation":	"V"	,"qtde":	1,	"vpa":	19.39},
{"date":	"05/08/2019",	"ticker":	"FLMA11",	"operation":	"V"	,"qtde":	50,	"vpa":	4.27},
{"date":	"06/08/2019",	"ticker":	"SLCE3",	"operation":	"C"	,"qtde":	9,	"vpa":	17.88},
{"date":	"06/08/2019",	"ticker":	"LOGG3",	"operation":	"V"	,"qtde":	10,	"vpa":	23.40},
{"date":	"07/08/2019",	"ticker":	"TAEE11",	"operation":	"C"	,"qtde":	10,	"vpa":	28.70},
{"date":	"07/08/2019",	"ticker":	"TAEE11",	"operation":	"C"	,"qtde":	10,	"vpa":	28.55},
{"date":	"07/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	12.78},
{"date":	"07/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	12.78},
{"date":	"07/08/2019",	"ticker":	"ETER1",	"operation":	"C"	,"qtde":	100,	"vpa":.21},
{"date":	"07/08/2019",	"ticker":	"TPIS3",	"operation":	"C"	,"qtde":	30,	"vpa":	1.69},
{"date":	"07/08/2019",	"ticker":	"PTBL3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.86},
{"date":	"07/08/2019",	"ticker":	"PTBL3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.87},
{"date":	"07/08/2019",	"ticker":	"PTBL3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.88},
{"date":	"07/08/2019",	"ticker":	"PTBL3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.89},
{"date":	"07/08/2019",	"ticker":	"SLCE3",	"operation":	"C"	,"qtde":	1,	"vpa":	17.85},
{"date":	"07/08/2019",	"ticker":	"JHSF3",	"operation":	"V"	,"qtde":	50,	"vpa":	3.99},
{"date":	"07/08/2019",	"ticker":	"SLED4",	"operation":	"V"	,"qtde":	40,	"vpa":	1.44},
{"date":	"08/08/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	8,	"vpa":	17.30},
{"date":	"08/08/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	17.31},
{"date":	"08/08/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	1,	"vpa":	17.32},
{"date":	"08/08/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	1,	"vpa":	17.32},
{"date":	"08/08/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	17.33},
{"date":	"08/08/2019",	"ticker":	"ECOR3",	"operation":	"V"	,"qtde":	50,	"vpa":	11.63},
{"date":	"08/08/2019",	"ticker":	"ABEV3",	"operation":	"V"	,"qtde":	20,	"vpa":	20.25},
{"date":	"09/08/2019",	"ticker":	"VULC3",	"operation":	"C"	,"qtde":	4,	"vpa":	7.72},
{"date":	"09/08/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	9.51},
{"date":	"09/08/2019",	"ticker":	"VULC3",	"operation":	"C"	,"qtde":	10,	"vpa":	7.73},
{"date":	"09/08/2019",	"ticker":	"VULC3",	"operation":	"C"	,"qtde":	10,	"vpa":	7.73},
{"date":	"09/08/2019",	"ticker":	"RAIL3",	"operation":	"C"	,"qtde":	5,	"vpa":	22.55},
{"date":	"09/08/2019",	"ticker":	"RAIL3",	"operation":	"C"	,"qtde":	5,	"vpa":	22.57},
{"date":	"09/08/2019",	"ticker":	"RAIL3",	"operation":	"C"	,"qtde":	5,	"vpa":	22.56},
{"date":	"09/08/2019",	"ticker":	"CARD3",	"operation":	"C"	,"qtde":	20,	"vpa":	6.45},
{"date":	"09/08/2019",	"ticker":	"BBDC4",	"operation":	"V"	,"qtde":	1,	"vpa":	34.44},
{"date":	"09/08/2019",	"ticker":	"GRND3",	"operation":	"V"	,"qtde":	20,	"vpa":	7.72},
{"date":	"09/08/2019",	"ticker":	"GOAU4",	"operation":	"V"	,"qtde":	10,	"vpa":	6.53},
{"date":	"09/08/2019",	"ticker":	"ETER1",	"operation":	"V"	,"qtde":	100,	"vpa":.18},
{"date":	"12/08/2019",	"ticker":	"VULC3",	"operation":	"C"	,"qtde":	10,	"vpa":	7.57},
{"date":	"12/08/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	9.45},
{"date":	"12/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	5,	"vpa":	12.65},
{"date":	"12/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	5,	"vpa":	12.64},
{"date":	"12/08/2019",	"ticker":	"RAIL3",	"operation":	"C"	,"qtde":	5,	"vpa":	22.42},
{"date":	"12/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	12.69},
{"date":	"12/08/2019",	"ticker":	"MDIA3",	"operation":	"V"	,"qtde":	7,	"vpa":	40.15},
{"date":	"12/08/2019",	"ticker":	"VULC3",	"operation":	"C"	,"qtde":	6,	"vpa":	7.55},
{"date":	"12/08/2019",	"ticker":	"BBDC4",	"operation":	"V"	,"qtde":	2,	"vpa":	33.65},
{"date":	"13/08/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":	1.49},
{"date":	"13/08/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	5,	"vpa":	9.38},
{"date":	"13/08/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	9.40},
{"date":	"13/08/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	5,	"vpa":	24.95},
{"date":	"13/08/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	5,	"vpa":	17.30},
{"date":	"13/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	20,	"vpa":	12.78},
{"date":	"13/08/2019",	"ticker":	"FLMA11",	"operation":	"V"	,"qtde":	100,	"vpa":	4.21},
{"date":	"14/08/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	16,	"vpa":	8.29},
{"date":	"14/08/2019",	"ticker":	"KLBN4",	"operation":	"C"	,"qtde":	100,	"vpa":	2.91},
{"date":	"14/08/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	9.31},
{"date":	"14/08/2019",	"ticker":	"TRIS3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.81},
{"date":	"14/08/2019",	"ticker":	"POMO4",	"operation":	"C"	,"qtde":	4,	"vpa":	3.81},
{"date":	"14/08/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	5,	"vpa":	17.32},
{"date":	"14/08/2019",	"ticker":	"RAIL3",	"operation":	"C"	,"qtde":	5,	"vpa":	22.58},
{"date":	"14/08/2019",	"ticker":	"POMO3",	"operation":	"C"	,"qtde":	6,	"vpa":	3.64},
{"date":	"14/08/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.45},
{"date":	"14/08/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.46},
{"date":	"14/08/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.30},
{"date":	"14/08/2019",	"ticker":	"TRIS3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.97},
{"date":	"14/08/2019",	"ticker":	"TRIS3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.97},
{"date":	"14/08/2019",	"ticker":	"TRIS3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.97},
{"date":	"14/08/2019",	"ticker":	"TRIS3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.95},
{"date":	"14/08/2019",	"ticker":	"TRIS3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.97},
{"date":	"14/08/2019",	"ticker":	"POMO4",	"operation":	"C"	,"qtde":	10,	"vpa":	3.78},
{"date":	"14/08/2019",	"ticker":	"POMO4",	"operation":	"C"	,"qtde":	10,	"vpa":	3.78},
{"date":	"14/08/2019",	"ticker":	"POMO4",	"operation":	"C"	,"qtde":	10,	"vpa":	3.78},
{"date":	"14/08/2019",	"ticker":	"SLCE3",	"operation":	"V"	,"qtde":	10,	"vpa":	17.88},
{"date":	"14/08/2019",	"ticker":	"CARD3",	"operation":	"V"	,"qtde":	20,	"vpa":	6.51},
{"date":	"14/08/2019",	"ticker":	"POMO3",	"operation":	"V"	,"qtde":	6,	"vpa":	3.61},
{"date":	"14/08/2019",	"ticker":	"TPIS3",	"operation":	"V"	,"qtde":	100,	"vpa":	1.82},
{"date":	"14/08/2019",	"ticker":	"MDIA3",	"operation":	"V"	,"qtde":	3,	"vpa":	37.77},
{"date":	"15/08/2019",	"ticker":	"RAIL3",	"operation":	"C"	,"qtde":	5,	"vpa":	22.03},
{"date":	"15/08/2019",	"ticker":	"VULC3",	"operation":	"C"	,"qtde":	10,	"vpa":	6.66},
{"date":	"15/08/2019",	"ticker":	"VULC3",	"operation":	"C"	,"qtde":	10,	"vpa":	6.69},
{"date":	"15/08/2019",	"ticker":	"SLCE3",	"operation":	"C"	,"qtde":	10,	"vpa":	17.31},
{"date":	"15/08/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	15,	"vpa":	9.10},
{"date":	"15/08/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	14,	"vpa":	7.79},
{"date":	"15/08/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":	1.17},
{"date":	"15/08/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	5,	"vpa":	23.95},
{"date":	"15/08/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	16.16},
{"date":	"16/08/2019",	"ticker":	"VVAR3",	"operation":	"C"	,"qtde":	10,	"vpa":	7.10},
{"date":	"16/08/2019",	"ticker":	"RAIL3",	"operation":	"C"	,"qtde":	10,	"vpa":	22.00},
{"date":	"16/08/2019",	"ticker":	"SUZB3",	"operation":	"C"	,"qtde":	10,	"vpa":	31.70},
{"date":	"19/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	30,	"vpa":	12.54},
{"date":	"19/08/2019",	"ticker":	"BBAS3",	"operation":	"C"	,"qtde":	1,	"vpa":	46.50},
{"date":	"19/08/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	5,	"vpa":	23.95},
{"date":	"20/08/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":.86},
{"date":	"20/08/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":.97},
{"date":	"20/08/2019",	"ticker":	"LIGT3",	"operation":	"V"	,"qtde":	10,	"vpa":	19.22},
{"date":	"20/08/2019",	"ticker":	"QUAL3",	"operation":	"V"	,"qtde":	10,	"vpa":	26.80},
{"date":	"20/08/2019",	"ticker":	"BBAS3",	"operation":	"V"	,"qtde":	1,	"vpa":	44.32},
{"date":	"21/08/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	23.75},
{"date":	"21/08/2019",	"ticker":	"OIBR3",	"operation":	"V"	,"qtde":	100,	"vpa":.79},
{"date":	"23/08/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	12.25},
{"date":	"23/08/2019",	"ticker":	"POMO4",	"operation":	"V"	,"qtde":	40,	"vpa":	3.52},
{"date":	"23/08/2019",	"ticker":	"KLBN4",	"operation":	"V"	,"qtde":	100,	"vpa":	2.78},
{"date":	"23/08/2019",	"ticker":	"TRIS3",	"operation":	"V"	,"qtde":	7,	"vpa":	9.85},
{"date":	"23/08/2019",	"ticker":	"TIET11",	"operation":	"V"	,"qtde":	20,	"vpa":	11.97},
{"date":	"26/08/2019",	"ticker":	"TRPL4",	"operation":	"V"	,"qtde":	12,	"vpa":	23.09},
{"date":	"26/08/2019",	"ticker":	"LEVE3",	"operation":	"V"	,"qtde":	20,	"vpa":	23.00},
{"date":	"26/08/2019",	"ticker":	"RAIL3",	"operation":	"V"	,"qtde":	20,	"vpa":	21.51},
{"date":	"26/08/2019",	"ticker":	"RAIL3",	"operation":	"V"	,"qtde":	20,	"vpa":	21.78},
{"date":	"26/08/2019",	"ticker":	"TRIS3",	"operation":	"V"	,"qtde":	53,	"vpa":	9.81},
{"date":	"04/09/2019",	"ticker":	"TECN3",	"operation":	"C"	,"qtde":	100,	"vpa":	2.05},
{"date":	"04/09/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	8.98},
{"date":	"04/09/2019",	"ticker":	"SLCE3",	"operation":	"C"	,"qtde":	10,	"vpa":	17.25},
{"date":	"04/09/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	12.00},
{"date":	"04/09/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	24.36},
{"date":	"05/09/2019",	"ticker":	"FESA4",	"operation":	"C"	,"qtde":	10,	"vpa":	19.25},
{"date":	"05/09/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	24.62},
{"date":	"05/09/2019",	"ticker":	"SUZB3",	"operation":	"C"	,"qtde":	10,	"vpa":	29.62},
{"date":	"05/09/2019",	"ticker":	"SUZB3",	"operation":	"C"	,"qtde":	10,	"vpa":	29.60},
{"date":	"05/09/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	24.64},
{"date":	"05/09/2019",	"ticker":	"TIET11",	"operation":	"C"	,"qtde":	10,	"vpa":	12.22},
{"date":	"06/09/2019",	"ticker":	"WIZS3",	"operation":	"C"	,"qtde":	10,	"vpa":	11.97},
{"date":	"06/09/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	6,	"vpa":	25.02},
{"date":	"06/09/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	20,	"vpa":	9.10},
{"date":	"06/09/2019",	"ticker":	"FESA4",	"operation":	"C"	,"qtde":	10,	"vpa":	19.37},
{"date":	"11/09/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	1,	"vpa":	24.85},
{"date":	"12/09/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	8,	"vpa":	24.49},
{"date":	"13/09/2019",	"ticker":	"SUZB3",	"operation":	"V"	,"qtde":	5,	"vpa":	33.20},
{"date":	"13/09/2019",	"ticker":	"TECN3",	"operation":	"C"	,"qtde":	100,	"vpa":	2.10},
{"date":	"13/09/2019",	"ticker":	"TECN3",	"operation":	"C"	,"qtde":	200,	"vpa":	2.11},
{"date":	"13/09/2019",	"ticker":	"SUZB3",	"operation":	"V"	,"qtde":	10,	"vpa":	33.35},
{"date":	"18/09/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	17.65},
{"date":	"18/09/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	17.70},
{"date":	"18/09/2019",	"ticker":	"SUZB3",	"operation":	"V"	,"qtde":	5,	"vpa":	33.92},
{"date":	"18/09/2019",	"ticker":	"SUZB3",	"operation":	"V"	,"qtde":	10,	"vpa":	33.90},
{"date":	"19/09/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	18.30},
{"date":	"19/09/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	18.27},
{"date":	"19/09/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	18.20},
{"date":	"19/09/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	18.10},
{"date":	"19/09/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	18.12},
{"date":	"23/09/2019",	"ticker":	"TIET11",	"operation":	"V"	,"qtde":	10,	"vpa":	12.12},
{"date":	"25/09/2019",	"ticker":	"SUZB3",	"operation":	"V"	,"qtde":	10,	"vpa":	32.30},
{"date":	"25/09/2019",	"ticker":	"SUZB3",	"operation":	"V"	,"qtde":	10,	"vpa":	32.35},
{"date":	"25/09/2019",	"ticker":	"SLCE3",	"operation":	"V"	,"qtde":	25,	"vpa":	18.54},
{"date":	"25/09/2019",	"ticker":	"SLCE3",	"operation":	"V"	,"qtde":	10,	"vpa":	18.52},
{"date":	"27/09/2019",	"ticker":	"VULC3",	"operation":	"V"	,"qtde":	35,	"vpa":	7.91},
{"date":	"27/09/2019",	"ticker":	"VULC3",	"operation":	"V"	,"qtde":	25,	"vpa":	7.91},
{"date":	"27/09/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	10,	"vpa":	13.30},
{"date":	"27/09/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	20,	"vpa":	13.29},
{"date":	"03/10/2019",	"ticker":	"GUAR3",	"operation":	"C"	,"qtde":	100,	"vpa":	18.75},
{"date":	"08/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	1,	"vpa":	16.21},
{"date":	"08/10/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	5,	"vpa":	12.33},
{"date":	"08/10/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	1,	"vpa":	23.69},
{"date":	"08/10/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	1,	"vpa":	23.68},
{"date":	"21/10/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	20.90},
{"date":	"21/10/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	20.86},
{"date":	"21/10/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	20.70},
{"date":	"21/10/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	20.67},
{"date":	"21/10/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	20.66},
{"date":	"21/10/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	9,	"vpa":	20.65},
{"date":	"21/10/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	1,	"vpa":	20.65},
{"date":	"21/10/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	20.63},
{"date":	"23/10/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	25,	"vpa":	13.68},
{"date":	"23/10/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	20,	"vpa":	13.65},
{"date":	"25/10/2019",	"ticker":	"LEVE3",	"operation":	"V"	,"qtde":	10,	"vpa":	24.90},
{"date":	"25/10/2019",	"ticker":	"LEVE3",	"operation":	"V"	,"qtde":	10,	"vpa":	24.80},
{"date":	"25/10/2019",	"ticker":	"LEVE3",	"operation":	"V"	,"qtde":	10,	"vpa":	24.80},
{"date":	"25/10/2019",	"ticker":	"LEVE3",	"operation":	"V"	,"qtde":	10,	"vpa":	24.79},
{"date":	"25/10/2019",	"ticker":	"LEVE3",	"operation":	"V"	,"qtde":	10,	"vpa":	24.79},
{"date":	"25/10/2019",	"ticker":	"LEVE3",	"operation":	"V"	,"qtde":	10,	"vpa":	24.79},
{"date":	"25/10/2019",	"ticker":	"SLCE3",	"operation":	"V"	,"qtde":	5,	"vpa":	18.29},
{"date":	"25/10/2019",	"ticker":	"SLCE3",	"operation":	"V"	,"qtde":	10,	"vpa":	18.28},
{"date":	"25/10/2019",	"ticker":	"TECN3",	"operation":	"V"	,"qtde":	80,	"vpa":	2.15},
{"date":	"25/10/2019",	"ticker":	"TECN3",	"operation":	"V"	,"qtde":	10,	"vpa":	2.16},
{"date":	"25/10/2019",	"ticker":	"TECN3",	"operation":	"V"	,"qtde":	10,	"vpa":	2.15},
{"date":	"25/10/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	1,	"vpa":	18.15},
{"date":	"25/10/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	18.18},
{"date":	"25/10/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	18.19},
{"date":	"25/10/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	18.20},
{"date":	"25/10/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	10,	"vpa":	13.75},
{"date":	"25/10/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	10,	"vpa":	13.76},
{"date":	"25/10/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	10,	"vpa":	13.74},
{"date":	"25/10/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	10,	"vpa":	13.73},
{"date":	"25/10/2019",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	10,	"vpa":	13.71},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	27},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	35},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	36},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	37},
{"date":	"31/10/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":6},
{"date":	"31/10/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":5},
{"date":	"31/10/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":2},
{"date":	"31/10/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":3},
{"date":	"31/10/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":4},
{"date":	"31/10/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":5},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	27},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	32},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	33},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	34},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	35},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	36},
{"date":	"31/10/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	37},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	85},
{"date":	"01/11/2019",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	8},
{"date":	"01/11/2019",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	75},
{"date":	"01/11/2019",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	73},
{"date":	"01/11/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	46},
{"date":	"01/11/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	45},
{"date":	"01/11/2019",	"ticker":	"GUAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	47},
{"date":	"01/11/2019",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	55},
{"date":	"01/11/2019",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	5},
{"date":	"01/11/2019",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	7},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	22},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	25},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	23},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	25},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	2},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	3},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	4},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	35},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	6},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	75},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	8},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	9},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	88},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	8},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	45},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	7},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	6},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	33},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	35},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	45},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	5},
{"date":	"01/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	4},
{"date":	"13/11/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":	1.01},
{"date":	"13/11/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":	1.01},
{"date":	"13/11/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":	1.01},
{"date":	"14/11/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	200,	"vpa":	1.00},
{"date":	"14/11/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	200,	"vpa":.99},
{"date":	"14/11/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	17.54},
{"date":	"14/11/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	17.53},
{"date":	"14/11/2019",	"ticker":	"PRIO3",	"operation":	"V"	,"qtde":	10,	"vpa":	19.55},
{"date":	"14/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	19.00},
{"date":	"14/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	1,	"vpa":	19.00},
{"date":	"14/11/2019",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	9,	"vpa":	19.20},
{"date":	"14/11/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":	1.01},
{"date":	"27/11/2019",	"ticker":	"OIBR3",	"operation":	"C"	,"qtde":	100,	"vpa":.94},
{"date":	"27/11/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	16.89},
{"date":	"27/11/2019",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	16.87},
{"date":	"10/12/2019",	"ticker":	"OIBR3",	"operation":	"V"	,"qtde":	100,	"vpa":.91},
{"date":	"10/12/2019",	"ticker":	"OIBR3",	"operation":	"V"	,"qtde":	100,	"vpa":.92},
{"date":	"10/12/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	10.27},
{"date":	"10/12/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	10.28},
{"date":	"10/12/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	10.29},
{"date":	"10/12/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	10.30},
{"date":	"10/12/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	10.28},
{"date":	"10/12/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	10.28},
{"date":	"10/12/2019",	"ticker":	"VVAR3",	"operation":	"V"	,"qtde":	10,	"vpa":	10.28},
{"date":	"13/12/2019",	"ticker":	"PTBL3",	"operation":	"C"	,"qtde":	10,	"vpa":	4.99},
{"date":	"13/12/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	9.98},
{"date":	"13/12/2019",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.56},
{"date":	"13/12/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	9.96},
{"date":	"13/12/2019",	"ticker":	"BRSR6",	"operation":	"C"	,"qtde":	10,	"vpa":	20.74},
{"date":	"13/12/2019",	"ticker":	"ODPV3",	"operation":	"C"	,"qtde":	10,	"vpa":	15.65},
{"date":	"13/12/2019",	"ticker":	"ODPV3",	"operation":	"C"	,"qtde":	10,	"vpa":	15.66},
{"date":	"13/12/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	10,	"vpa":	22.55},
{"date":	"13/12/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	24.90},
{"date":	"13/12/2019",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	24.93},
{"date":	"13/12/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	9.96},
{"date":	"13/12/2019",	"ticker":	"SHUL4",	"operation":	"C"	,"qtde":	10,	"vpa":	9.94},
{"date":	"17/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	100,	"vpa":	5.15},
{"date":	"17/12/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	10,	"vpa":	22.35},
{"date":	"17/12/2019",	"ticker":	"GFSA3",	"operation":	"C"	,"qtde":	100,	"vpa":	8.00},
{"date":	"17/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	100,	"vpa":	5.10},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.80},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.85},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.82},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.75},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.70},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.80},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.90},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.90},
{"date":	"18/12/2019",	"ticker":	"FHER3",	"operation":	"C"	,"qtde":	10,	"vpa":	3.95},
{"date":	"19/12/2019",	"ticker":	"TRPL4",	"operation":	"C"	,"qtde":	10,	"vpa":	22.28},
{"date":	"19/12/2019",	"ticker":	"GFSA3",	"operation":	"C"	,"qtde":	6,	"vpa":	7.64},
{"date":	"6/1/2020",	"ticker":	"GFSA3",	"operation":	"V"	,"qtde":	100,	"vpa":	10.24},
{"date":	"6/1/2020",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	30,	"vpa":	14.10},
{"date":	"9/1/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.79},
{"date":	"9/1/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.74},
{"date":	"9/1/2020",	"ticker":	"GFSA3",	"operation":	"C"	,"qtde":	10,	"vpa":	9.32},
{"date":	"9/1/2020",	"ticker":	"GFSA3",	"operation":	"C"	,"qtde":	10,	"vpa":	9.17},
{"date":	"9/1/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.71},
{"date":	"9/1/2020",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	5,	"vpa":	29.31},
{"date":	"9/1/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.70},
{"date":	"9/1/2020",	"ticker":	"GFSA3",	"operation":	"C"	,"qtde":	10,	"vpa":	9.37},
{"date":	"9/1/2020",	"ticker":	"GFSA3",	"operation":	"C"	,"qtde":	4,	"vpa":	9.35},
{"date":	"9/1/2020",	"ticker":	"PTBL3",	"operation":	"C"	,"qtde":	10,	"vpa":	5.75},
{"date":	"9/1/2020",	"ticker":	"GFSA3",	"operation":	"C"	,"qtde":	10,	"vpa":	9.17},
{"date":	"9/1/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.63},
{"date":	"9/1/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.63},
{"date":	"10/1/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.60},
{"date":	"13/01/2020",	"ticker":	"PTBL3",	"operation":	"C"	,"qtde":	20,	"vpa":	5.65},
{"date":	"16/01/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.44},
{"date":	"16/01/2020",	"ticker":	"ITSA4",	"operation":	"C"	,"qtde":	10,	"vpa":	13.45},
{"date":	"17/01/2020",	"ticker":	"CARD3",	"operation":	"C"	,"qtde":	10,	"vpa":	13.20},
{"date":	"17/01/2020",	"ticker":	"CARD3",	"operation":	"C"	,"qtde":	10,	"vpa":	13.25},
{"date":	"23/01/2020",	"ticker":	"ITSA4",	"operation":	"V"	,"qtde":	20,	"vpa":	13.20},
{"date":	"29/01/2020",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	5,	"vpa":	46.97},
{"date":	"30/01/2020",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	5,	"vpa":	44.59},
{"date":	"31/01/2020",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	23.70},
{"date":	"31/01/2020",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	24.00},
{"date":	"31/01/2020",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	23.90},
{"date":	"2020-02-05",	"ticker":	"TRPL4",	"operation":	"V"	,"qtde":	5,	"vpa":	22.60},
{"date":	"2020-02-05",	"ticker":	"TRPL4",	"operation":	"V"	,"qtde":	5,	"vpa":	22.61},
{"date":	"2020-02-05",	"ticker":	"ODPV3",	"operation":	"V"	,"qtde":	20,	"vpa":	17.96},
{"date":	"2020-02-05",	"ticker":	"WIZS3",	"operation":	"V"	,"qtde":	10,	"vpa":	15.99},
{"date":	"2020-02-05",	"ticker":	"EZTC3",	"operation":	"C"	,"qtde":	10,	"vpa":	54.73},
{"date":	"2020-02-05",	"ticker":	"EZTC3",	"operation":	"C"	,"qtde":	5,	"vpa":	54.69},
{"date":	"2020-02-05",	"ticker":	"SHUL4",	"operation":	"V"	,"qtde":	10,	"vpa":	13.40},
{"date":	"2020-02-05",	"ticker":	"SHUL4",	"operation":	"V"	,"qtde":	10,	"vpa":	13.43},
{"date":	"2020-02-05",	"ticker":	"EZTC3",	"operation":	"C"	,"qtde":	5,	"vpa":	53.89},
{"date":	"2020-02-05",	"ticker":	"BRSR6",	"operation":	"V"	,"qtde":	10,	"vpa":	20.92},
{"date":	"2020-02-05",	"ticker":	"EZTC3",	"operation":	"C"	,"qtde":	5,	"vpa":	53.73},
{"date":	"2020-02-11",	"ticker":	"EZTC3",	"operation":	"C"	,"qtde":	10,	"vpa":	50.50},
{"date":	"2020-02-11",	"ticker":	"CARD3",	"operation":	"C"	,"qtde":	20,	"vpa":	11.58},
{"date":	"2020-02-11",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	10,	"vpa":	42.40},
{"date":	"2020-02-11",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	23.24},
{"date":	"2020-02-11",	"ticker":	"TECN3",	"operation":	"C"	,"qtde":	100,	"vpa":	2.74},
{"date":	"2020-02-11",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	23.26},
{"date":	"2020-02-11",	"ticker":	"GFSA3",	"operation":	"C"	,"qtde":	10,	"vpa":	8.47},
{"date":	"2020-02-11",	"ticker":	"PRIO3",	"operation":	"C"	,"qtde":	5,	"vpa":	42.10},
{"date":	"2020-02-11",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	30.60},
{"date":	"2020-02-11",	"ticker":	"LEVE3",	"operation":	"C"	,"qtde":	10,	"vpa":	30.60},
{"date":	"2020-02-11",	"ticker":	"PTBL3",	"operation":	"C"	,"qtde":	10,	"vpa":	5.66},
{"date":	"2020-02-11",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	23.48},
{"date":	"2020-02-11",	"ticker":	"CARD3",	"operation":	"C"	,"qtde":	30,	"vpa":	11.58},
{"date":	"2020-02-11",	"ticker":	"LCAM3",	"operation":	"V"	,"qtde":	10,	"vpa":	23.63},
{"date":	"2020-02-11",	"ticker":	"LCAM3",	"operation":	"C"	,"qtde":	10,	"vpa":	23.52}
]