<div class=" pl-3 listview listview--hover">
  <a class="listview__item d-flex justify-content-between"
    (click)="editDeadline('toStarts', ' prazo para iniciar o atendimento')">
    <span>Para iniciar o chamado</span>
    <span class="badge badge-soft-info" *ngIf="deadline?.toStarts">{{deadline?.toStarts}} horas
    </span>
  </a>

  <a class="listview__item d-flex justify-content-between"
    (click)="editDeadline('toAttendsHigh', ' prazo para atender na prioridade alta')">
    <span>Atender - Prioridade alta</span>
    <span class="badge badge-soft-info" *ngIf="deadline?.toAttendsHigh">{{deadline?.toAttendsHigh}} horas
    </span>
  </a>
  <a class="listview__item d-flex justify-content-between"
    (click)="editDeadline('toAttendsMedium', ' prazo para atender na prioridade média')">
    <span>Atender - Prioridade média</span>
    <span class="badge badge-soft-info" *ngIf="deadline?.toAttendsMedium">{{deadline?.toAttendsMedium}} horas
    </span>
  </a>
  <a class="listview__item d-flex justify-content-between"
    (click)="editDeadline('toAttendsLow', ' prazo para atender na prioridade baixa')">
    <span>Atender - Prioridade baixa</span>
    <span class="badge badge-soft-info" *ngIf="deadline?.toAttendsLow">{{deadline?.toAttendsLow}} horas
    </span>
  </a>
</div>