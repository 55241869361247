import { Component, OnInit } from '@angular/core';
import { data } from './data';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  data = data;

  constructor() { }

  ngOnInit() {
    this.data.map(r => {
      r.cust = r.vpa * r.qtde * 0.000325;
      return r;
    }).reverse();
  }

}
