<style>
    .current-module {
        background: rgba(255, 255, 255, 0.0712)
    }
</style>

<header class="header">
    <div class="navigation-trigger hidden-xl-up" data-ma-action="aside-open" data-ma-target=".sidebar">
        <div class="navigation-trigger__inner">
            <i class="navigation-trigger__line"></i>
            <i class="navigation-trigger__line"></i>
            <i class="navigation-trigger__line"></i>
        </div>
    </div>

    <div class="header__logo  ">
        <h1><a (click)="goHome()">
                <img src="../../assets/images/logo-text.png" alt="" style="height: 2.8em;">
            </a></h1>
    </div>
    <ul class="top-nav ml-0">
        <li class="dropdown hidden-xs-down">
            <a data-target="#" data-toggle="dropdown" class="current-module pl-4 pr-5 py-3"><i
                    class="zmdi zmdi-apps"></i> &nbsp;
                <span style="vertical-align: .30em;">
                    {{currentModule}} &nbsp;&nbsp;&nbsp;<i class="fa fa-angle-down"></i>
                </span>
            </a>

            <div class="dropdown-menu dropdown-menu-right dropdown-menu--block" role="menu">
                <div class="row app-shortcuts">
                    <a class="col-4 app-shortcuts__item" *ngFor="let m of modules" (click)="navigate('/'+m?.slug)">
                        <i class="{{m?.icon}}"></i>
                        <small class="">{{m?.name}}</small>
                        <span class="app-shortcuts__helper {{m?.css_class}}"></span>
                    </a>
                    <a class="col-4 app-shortcuts__item" hidden (click)="navigate('/observatorio')">
                        <i class="fad fa-people-carry"></i>
                        <small class="">Observatório</small>
                        <span class="app-shortcuts__helper bg-blue"></span>
                    </a>
                    <a class="col-4 app-shortcuts__item" (click)="navigate('/fastfood')">
                        <i class="zmdi zmdi-shopping-basket"></i>
                        <small class="">Lanchonete</small>
                        <span class="app-shortcuts__helper bg-orange"></span>
                    </a>

                    <a class="col-4 app-shortcuts__item" hidden (click)="navigate('/pesquisas')">
                        <i class="fal fa-truck-container"></i>
                        <small class="">Entregas</small>
                        <span class="app-shortcuts__helper bg-teal"></span>
                    </a>
                    <a class="col-4 app-shortcuts__item" hidden (click)="navigate('/admin')">
                        <i class="fas fa-building"></i>
                        <small class="">Administraçao</small>
                        <span class="app-shortcuts__helper bg-dark"></span>
                    </a>
                    <a class="col-4 app-shortcuts__item" hidden data-target="#">
                        <i class="zmdi zmdi-image"></i>
                        <small class="">Gallery</small>
                        <span class="app-shortcuts__helper bg-light-green"></span>
                    </a>
                </div>
            </div>
        </li>
    </ul>
    <form class="search" hidden>
        <div class="search__inner">
            <input type="text" class="search__text" placeholder="Search for people, files, documents...">
            <i class="zmdi zmdi-search search__helper" data-ma-action="search-close"></i>
        </div>
    </form>

    <ul class="top-nav">
        <li class="hidden-xl-up"><a data-target="#" data-ma-action="search-open"><i
                    class="zmdi zmdi-search"></i></a></li>

        <li class="dropdown top-nav__notifications" *ngIf="notifications?.length>0">
            <a data-target="#" data-toggle="dropdown" class="pb-1" [ngClass]="{'top-nav__notify': hasNewNotification}">
                <i class="fal fa-bell" style="font-size: 1.3em;"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu--block">
                <div class="listview listview--hover">
                    <div class="listview__header">
                        <span style="text-transform: initial;">Notificações</span>
                        <div class="actions">
                            <a data-target="#" class="actions__item zmdi zmdi-check-all"
                                data-ma-action="notifications-clear"></a>
                        </div>
                    </div>
                    <ng-scrollbar [visibility]="'hover'"
                        style="max-height:37vh; min-height:18vh; overflow-y: auto;">
                        <a class="listview__item p-0" style="cursor: default;" *ngFor="let not of notifications"
                            [ngClass]="{'listview__item--active': !not?.seen}"
                            (mouseenter)="readNotification(not)">
                            <div class="listview__cntent p-3" style="border-bottom: 1px solid #0000000f;">
                                <div class="listview__heading" *ngIf="not?.title">{{not?.title}}</div>
                                <p class="mb-0" style="font-size: .91em;">{{not?.text}}</p>
                                <small class="text-muted">{{not?.createdAt | amTimeAgo}}</small>
                            </div>
                        </a>
                    </ng-scrollbar>
                </div>
            </div>
        </li>

        <li class="dropdown hidden-xs-down" hidden>
            <a data-target="#" data-toggle="dropdown"><i class="zmdi zmdi-check-circle"></i></a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu--block" role="menu">
                <div class="listview listview--hover">
                    <div class="listview__header">Tasks</div>
                    <a data-target="#" class="listview__item">
                        <div class="listview__content">
                            <div class="listview__heading mb-2">HTML5 Validation Report</div>
                            <div class="progress">
                                <div class="progress-bar" style="width: 75%" aria-valuenow="75" aria-valuemin="0"
                                    aria-valuemax="100"></div>
                            </div>
                        </div>
                    </a>
                    <a data-target="#" class="listview__item">
                        <div class="listview__content">
                            <div class="listview__heading mb-2">Google Chrome Extension</div>

                            <div class="progress">
                                <div class="progress-bar bg-warning" style="width: 43%" aria-valuenow="43"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </a>

                    <a data-target="#" class="listview__item">
                        <div class="listview__content">
                            <div class="listview__heading mb-2">Social Intranet Projects</div>

                            <div class="progress">
                                <div class="progress-bar bg-success" style="width: 20%" aria-valuenow="20"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </a>

                    <a data-target="#" class="listview__item">
                        <div class="listview__content">
                            <div class="listview__heading mb-2">Bootstrap Admin Template</div>

                            <div class="progress">
                                <div class="progress-bar bg-info" style="width: 60%" aria-valuenow="60"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </a>

                    <a data-target="#" class="listview__item">
                        <div class="listview__content">
                            <div class="listview__heading mb-2">Youtube Client App</div>

                            <div class="progress">
                                <div class="progress-bar bg-danger" style="width: 80%" aria-valuenow="80"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </a>

                    <a data-target="#" class="view-more">View all tasks</a>
                </div>
            </div>
        </li>
        <li class="dropdown hidden-xs-down">
            <a data-target="#" data-toggle="dropdown" class="py-4">
                <span class="p-4">
                    <i class="fa fa-user"></i> &nbsp; {{currentUser?.name}}
                </span></a>

            <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item theme-switch">
                    Escolha uma cor

                    <div class="btn-group btn-group-toggle btn-group--colors" data-toggle="buttons">
                        <label (click)="changeTheme('green')" class="btn bg-green"
                            [ngClass]="{'active':theme=='green'}">
                            <input type="radio" value="green"></label>
                        <label (click)="changeTheme('blue')" class="btn bg-blue"
                            [ngClass]="{'active':theme=='blue'}"><input type="radio"
                                value="blue"></label>
                        <label (click)="changeTheme('red')" class="btn bg-red"
                            [ngClass]="{'active':theme=='red'}"><input type="radio"
                                value="red"></label>
                        <label (click)="changeTheme('orange')" class="btn bg-orange"
                            [ngClass]="{'active':theme=='orange'}"><input
                                type="radio"
                                value="orange"></label>
                        <label (click)="changeTheme('teal')" class="btn bg-teal"
                            [ngClass]="{'active':theme=='teal'}"><input type="radio"
                                value="teal"></label>

                        <div class="clearfix mt-2"></div>

                        <label (click)="changeTheme('cyan')" class="btn bg-cyan"
                            [ngClass]="{'active':theme=='cyan'}"><input type="radio"
                                value="cyan"></label>
                        <label (click)="changeTheme('blue-grey')" class="btn bg-blue-grey"
                            [ngClass]="{'active':theme=='blue-green'}"><input
                                type="radio"
                                value="blue-grey"></label>
                        <label (click)="changeTheme('purple')" class="btn bg-purple"
                            [ngClass]="{'active':theme=='purple'}"><input
                                type="radio"
                                value="purple"></label>
                        <label (click)="changeTheme('indigo')" class="btn bg-indigo"
                            [ngClass]="{'active':theme=='indigo'}"><input
                                type="radio"
                                value="indigo"></label>
                        <label (click)="changeTheme('brown')" class="btn bg-brown"
                            [ngClass]="{'active':theme=='brown'}"><input
                                type="radio"
                                value="brown"></label>
                    </div>
                </div>
                <hr>
                <a href="" class="dropdown-divider"></a>
                <a (click)="logout()" class="dropdown-item">Sair</a>
            </div>
        </li>
        <li class="dropdown hidden-xs-down">
            <a href="" data-toggle="dropdown" class="py-4">
                <span class="p-2">
                    <i class="fas fa-cogs"></i>
                </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" (click)="toggleFullscreen()">Tela cheia</a>
                <a class="dropdown-item" (click)="clearCache()">Limpar cache</a>
            </div>
        </li>


    </ul>
</header>