import { Component, OnInit } from '@angular/core';
import { CacheService } from '@app/_services/cache.service';
import { firestoreService } from '@app/_services/firestore.service';
import { SharedService } from '@app/_services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TicketComponent } from '../tickets/ticket/ticket.component';

@Component({
  selector: 'app-admin-observatory',
  templateUrl: './admin-observatory.component.html',
  styleUrls: ['./admin-observatory.component.css']
})
export class AdminObservatoryComponent implements OnInit {
  teams;
  teamsToFilter = [];
  users;
  rawTickets = { new: [], inProgress: [], late: [], finished: [], waiting: [] };

  membersTmp;
  membersAvailable;
  showAppendMember;
  defaultTimes;
  currentTeam;
  currentTeamToFilter;
  showSideSectors;
  loading = true;
  tickets = { new: [], inProgress: [], late: [], finished: [], waiting: [] };
  modalRef: any;
  constructor(private fs: firestoreService, private modalService: NgbModal, private cs: CacheService, private ss: SharedService) {
  }

  ngOnInit() {
    this.fs.colWithIds$('/infrastructure/access/users', ref => ref.orderBy('name')).subscribe(r => this.users = r.map(r1 => r1 = { id: r1.id, name: r1.name, email: r1.email }));
    this.fs.doc$('/modules/observatory').subscribe((r: any) => this.defaultTimes = r.defaultTimes);
    this.loadTeams();
    this.loadTickets();
  }

  processTickets(tickets) {
    this.loading = true;
    return tickets.map(r => {
      r.previa = r.message.replace(/<\/?[^>]+(>|$)/g, " ").replace(new RegExp("&nbsp;", "g"), " ");
      const team = this.teams.find(t => ((r.teamRequested && r.teamRequested.id === t.id) || (r.assignedTo && t.members && t.members.map(a => a.id).includes(r.assignedTo.id))));
      this.teamsToFilter = (team && !this.teamsToFilter.includes(team)) ? [...this.teamsToFilter, team] : this.teamsToFilter;
      if (r.mustStartsAt) r.ms = moment(r.mustStartsAt.toDate()).diff(moment().toDate(), 'minutes');
      if (r.mustAttendsAt) r.ma = moment(r.mustAttendsAt.toDate()).diff(moment().toDate(), 'minutes');
      this.loading = false;
      return r;
    });
  }


  loadTickets() {
    this.loading = true;
    const maxAge = moment()
      .subtract(30, "days")
      .toDate();
    this.tickets = { new: [], inProgress: [], late: [], finished: [], waiting: [] }
    this.fs.colWithIds$('/modules/observatory/tickets', ref => ref.where('status', '==', 'Novo')).subscribe(r => this.tickets.new = this.processTickets(r).filter(a => !(a.ma < 0) && !(a.ms < 0)));

    Observable.forkJoin(this.fs.colWithIds$('/modules/observatory/tickets', ref => ref.where('status', '==', 'Em andamento')),
      this.fs.colWithIds$('/modules/observatory/tickets', ref => ref.where('status', '==', 'Atendido'))).subscribe(res => {
        res.map(r => this.tickets.inProgress = [...this.tickets.inProgress, ...this.processTickets(r)]);
      });

    this.fs.colWithIds$('/modules/observatory/tickets', ref => ref.where("createdAt", ">", moment(moment().subtract(35, 'days')).toDate())).subscribe(r => this.tickets.finished = this.processTickets(r.filter(a => a.status === 'Finalizado')));
    this.fs.colWithIds$('/modules/observatory/tickets', ref => ref.where('status', '==', 'Atendido')).subscribe(r => this.tickets.waiting = this.processTickets(r));

    Observable.forkJoin([this.fs.colWithIds$("/modules/observatory/tickets", ref => ref.where("status", "==", 'Novo')).pipe(map(r =>
      r = r.filter(a => (a.mustStartsAt && moment(a.mustStartsAt.toDate()).diff(moment().toDate(), 'minutes') <= 0)))),
    this.fs.colWithIds$("/modules/observatory/tickets", ref => ref.where("status", "==", 'Em andamento')).pipe(map(r =>
      r = r.filter(a => (a.mustStartsAt && moment(a.mustAttendsAt.toDate()).diff(moment().toDate(), 'minutes') <= 0))))])
      .subscribe(r => r.map(r1 => (this.tickets.late = [...this.tickets.late, ...this.processTickets(r1)])), null, () => { this.loading = false; });
  }

  readTicket(ticket) {
    this.modalRef = this.modalService.open(TicketComponent, {
      windowClass: "lg transparent animated fadeIn faster",
      centered: true
    });
    this.modalRef.componentInstance.ticketSelected = ticket;
    this.modalRef.componentInstance.readOnly = true;
  }

  loadTeams(id?) {
    this.loading = true;
    this.fs.colWithIds$('/modules/observatory/teams').subscribe(r => {
      this.teams = r.sort((a, b) => a.name.localeCompare(b.name));
      if (id) this.carregaTeam(r.find(r => r.id === id));
    }, null, () => this.loading = false);
  }

  updateMembers(m) {
    m.filter(m => !m.email).map(r => delete r.email)
    return this.fs.update('/modules/observatory/teams/' + this.currentTeam.id, { members: m });
  }

  attachMember() {
    this.loading = true;
    const path = '/infrastructure/access/permissions/observatorio';
    this.fs.doc$(path).subscribe((r: any) => {
      const members = (r && r.members) ? r.members : [];
      this.currentTeam.members.map(memberFromTeam => {
        if (members.every(idPermission => memberFromTeam.id !== idPermission))
          members.push(memberFromTeam.id);
        return memberFromTeam;
      });
      this.fs.set(path, { members: members }, true);
    });
    this.updateMembers(this.currentTeam.members).then(r => {
      this.loading = false;
      this.showAppendMember = false
      this.membersTmp = null;
    });
  }

  filtered = false;

  filterTickets() {
    if (!this.filtered) this.rawTickets = Object.assign(Object.create(this.tickets), this.tickets);
    else this.tickets = Object.assign(Object.create(this.rawTickets), this.rawTickets);
    if (!this.currentTeamToFilter) return;
    this.filtered = true;

    console.log(this.rawTickets);

    Object.keys(this.tickets).filter(r => this.tickets && this.tickets[r].length).map(s => {
      this.tickets[s] = this.rawTickets[s].filter(r => (r.teamRequested && r.teamRequested.id === this.currentTeamToFilter.id)
        || (r.assignedTo && this.currentTeamToFilter.members &&
          this.currentTeamToFilter.members.map(a => a.id).includes(r.assignedTo.id)))
    })
  }

  detachMember(member) {
    if (!member.id) return;
    Swal.fire({
      title: "Tem certeza?",
      text: "Os dados não poderão ser recuperados.!",
      icon: "warning",
      animation: false,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, pode ir!"
    }).then(result => {
      if (result.value != null) {
        this.loading = true
        this.updateMembers(this.currentTeam.members.filter(r => r.id !== member.id)).then(r => {
          this.loading = false;
          this.currentTeam.members = this.currentTeam.members.filter(r => r.id !== member.id);
          this.carregaTeam(this.currentTeam);
          Swal.fire({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: "Membro removido"
          });
        });
      }
    });
  }

  addTeam() {
    Swal.fire({
      title: "Informe o nome do setor",
      inputPlaceholder: "Nome do setor",
      animation: false,
      input: "text",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Cadastrar",
      preConfirm: v => {
        if (!v || v.length < 3)
          return Swal.showValidationMessage(`Digite o nome`);
        else if (this.teams.map(r => r.name).includes(v))
          return Swal.showValidationMessage(`Já existe um setor com este nome`);
        else
          return this.fs
            .add(`/modules/observatory/teams`, { name: v }).then((r: any) =>
              this.loadTeams(r.id || null)
            )
            .catch(erro => {
              Swal.showValidationMessage(`Não foi possível cadastrar`);
            });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.value != null) {
        Swal.fire({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          title: "Setor cadastrado"
        });
      }
    });
  }




  deleteTeam(tm?) {
    const team = this.currentTeam || tm;
    this.ss.delete({ id: team.id, name: team.name }, '/modules/observatory/teams', 2, 'Nome do time').then(r => {
      this.loading = false;
      this.teams = this.teams.filter(r => r.id !== team.id);
      if (this.teams && this.teams.length > 0) {
        this.carregaTeam(this.teams[0])
      }
      Swal.fire({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: "Setor removido"
      });
    });
  }

  carregaTeam(team) {
    if (this.currentTeam && this.membersTmp) this.currentTeam.members = this.membersTmp;
    this.membersTmp = null;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 300);
    this.showAppendMember = false;
    //  this.membersAvailable = this.users.filter(r => !team.members || !team.members.map(a => a.id).includes(r.id));
    this.currentTeam = team;
  }
}
