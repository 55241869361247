import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { environment } from '@environments/environment';
import * as moment from "moment";
import { OneSignalService } from 'ngx-onesignal';
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";
import { take } from "rxjs/operators";
import { User } from "./_models";
import { Role } from "./_models/role";
import { AuthenticationService } from "./_services";
import { CacheService } from './_services/cache.service';

@Component({ selector: "app", templateUrl: "app.component.html" })
export class AppComponent implements OnInit, OnDestroy {
  currentUser: User;
  message;
  currentRoute: any;

  sbcurrentUser = null;
  sbTitle = null;
  sbRouter = null;
  constructor(
    private router: Router,
    private titleService: Title,
    public readonly onesignal: OneSignalService,

    private activatedRoute: ActivatedRoute,
    private cs: CacheService,
    private authenticationService: AuthenticationService
  ) {
    moment.locale("pt-br");
    this.updateVersion();

    (<any>window).ngxOnesignal = onesignal;
    (<any>window).ngxOnesignal = onesignal;
    (<any>window).ngxOnesignal = onesignal;

    (<any>window).ngxOnesignal.push(['addListenerForNotificationOpened', function (data) {
      console.log("Received NotificationOpened:");
      console.log(data);
    }]);


  }


  updateVersion() {
    if (environment.production)
      if (!(+localStorage.getItem('version') >= environment.version)) {
        this.cs.clearCache();
        localStorage.clear();
        this.authenticationService.SignOut();
        console.log("atualizado");
        localStorage.setItem('version', environment.version + "");
      }
  }

  ngOnInit() {
    this.sbTitle = this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter(route => route.outlet === "primary")
      .mergeMap(route => route.data)
      .subscribe(event =>
        this.titleService.setTitle(
          "Nutribem" + (event["title"] ? " - " + event["title"] : "")
        )
      );
    this.router.events
      .filter((event: any) => event instanceof NavigationEnd)
      .pipe(take(1))
      .subscribe(
        event => {
          if (event.url === "/") {
            if (this.currentUser && this.currentUser.role === Role.Shop_owner) {
              this.router.navigate(["/meu-restaurante"]);
            } else if (
              this.currentUser &&
              this.currentUser.role === Role.Admin
            ) {
              console.log("admin");
              this.router.navigate(["/admin/overview"]);
            }
          }
        },
        error => { },
        () => { }
      );
  }


  ngOnDestroy() {
    if (this.sbcurrentUser) {
      this.sbcurrentUser.unsubscribe();
    }
    if (this.sbTitle) {
      this.sbTitle.unsubscribe();
    }
  }

}



