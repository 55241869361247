<div class="row">
  <div class="col-auto d-flex">



    <div class="bg-white animated fadeIn pt-2 pl-3" *ngIf="showSideSectors" style=" width: 20vw;height: 93vh;">
      <nav>
        <div class="nav nav-tabs pt-2 px-3" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
            aria-controls="nav-home" aria-selected="true">Setores</a>
          <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
            aria-controls="nav-profile" aria-selected="false">Definições</a>
        </div>
      </nav>
      <ng-scrollbar [visibility]="'hover'"
        style="max-height:87vh; min-height:87vh; background-color: white;  overflow-y: auto;">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="row  justify-content-center">
              <div class="col-12 pl-3">
                <div class="d-flex row no-gutters">
                  <div class="col-12 " *ngIf="!currentTeam?.id">
                    <div class="d-flex flex-column justify-content-between" style="min-height: 40vh;">
                      <div class="">
                        <div class="card-body p-4">
                          <button class="btn btn-block btn-sm btn-primary py-3" (click)="addTeam()">
                            <i class="fa fa-plus"></i>
                            Novo setor</button>
                          <br>

                          <ul class="pp-list pl-0">
                            <li class="pp-list-item p-1" *ngFor="let team of teams  ">
                              <div class="pp-list-title" (click)="carregaTeam(team)"
                                title="criado {{(team?.createdAt)?(team?.createdAt.toDate() | amTimeAgo):'team?.name'}}">
                                <h2 style="transform: scale(.8);">{{team?.name}}
                                  <p *ngIf="team?.members">Membros: {{team?.members?.length}} &nbsp;</p>
                                </h2>
                              </div>
                              <div class="dropdown float-right mt-n4">
                                <a href="#" class="dropdown-toggle p-2 mt-n2 arrow-none card-drop"
                                  data-toggle="dropdown"
                                  aria-expanded="false">
                                  <i class="zmdi zmdi-more-vert"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a (click)="deleteTeam(team)" class="dropdown-item">Remover</a>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <br>
                          <br>
                          <br>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="currentTeam?.id">
                    <button class="btn btn-sm btn-link" (click)="currentTeam=null">Voltar</button>
                    <div class=" mail-detail px-1 ">
                      <div class="border-bottom">
                        <div class="d-flex justify-content-start flex-wrap-reverse">
                          <h5 class="mt-0 mb-0 pl-2 pb-0 font-17">{{currentTeam?.name}}</h5>
                        </div>
                      </div>

                      <div class="body todo_list pt-0 px-3 mt-4 rounded">
                        <div class="card mx-nlg mb-1 bg-white border rounded shadow-lg"
                          *ngIf="showAppendMember">
                          <div class="card-header">
                            <h5 class="font-weight-light">Editar Membros</h5>
                          </div>
                          <div class="card-body py-1">
                            <ng-select [items]="users"
                              [(ngModel)]="currentTeam.members"
                              [multiple]="true"
                              [hideSelected]="true"
                              bindLabel="name"
                              [closeOnSelect]="false"
                              placeholder="Integrantes">
                              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <div style="margin: 10px 0;">
                                  <h5 class="card-title" [ngOptionHighlight]="search">{{item.name}}</h5>
                                  <h6 class="card-subtitle mb-2 text-muted">{{item.email}}</h6>
                                </div>
                              </ng-template>
                            </ng-select>
                            <small class="text-muted">Se não encontrar, envie um convite para o e-mail do
                              usuário</small>
                          </div>
                          <div class="card-footer">
                            <div class="row justify-content-end">
                              <div class="col-auto">
                                <a class="btn btn-sm mr-2 text-muted"
                                  (click)="[showAppendMember=!showAppendMember,currentTeam.members=membersTmp]">
                                  Cancelar
                                </a>
                                <button class="btn btn-sm btn-primary p-2  " type="button"
                                  (click)="attachMember()"
                                  id="button-addon2">
                                  <i class="fad fa-save"></i> Salvar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="!showAppendMember">
                          <div class="mb-2"
                            *ngIf="currentTeam?.members?.length>0">
                            <div class="d-flex justify-content-between flex-wrap-reverse">
                              <span class="mt-0 mb-0 font-17">Membros:</span>
                              <div>
                                <button class="btn btn-primary rounded btn-sm mb-1"
                                  (click)="[showAppendMember=!showAppendMember, membersTmp=currentTeam?.members]">Adicionar
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="placeholder placeholder-sm"
                            *ngIf="!currentTeam?.members || currentTeam?.members?.length===0">
                            <p>Você ainda não inseriu membros neste setor</p>
                            <button class="btn btn-secondary rounded btn-sm mb-1"
                              (click)="[showAppendMember=!showAppendMember, membersTmp=[]]"
                              ngbTooltip="Inserir membros no grupo">Adicionar</button>
                          </div>
                        </div>
                        <div class="contacts no-gutters row pt-3" *ngIf="!showAppendMember">
                          <div class="col-lg-6 col-sm-6 col-12 px-1" *ngFor="let member of currentTeam?.members">
                            <div class="contacts__item  p-3 border bg-light card ">
                              <div class="contacts__info mb-1">
                                <strong>{{member?.name}}</strong>
                              </div>
                              <button class="btn btn-outline-secondary btn-sm"
                                (click)="detachMember(member)">Remover</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="listview listview--hover">
                        <span class="mt-0 mb-0 font-17 text-dark">Prazos do setor:<br><br></span>
                        <app-deadline [team]="currentTeam"></app-deadline>
                      </div>
                      <div>
                        <br>
                        <button class="btn btn-link text-danger rounded btn-sm mb-1"
                          (click)="deleteTeam()">Excluir setor</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <span class="mt-0 mb-0 font-17 text-dark">Prazos gerais:<br><br></span>
            <app-deadline [deadline]="defaultTimes"></app-deadline>
            <hr>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <div class="side-opener pt-4" (click)="showSideSectors=!showSideSectors"
      ngbTooltip="{{(!showSideSectors)?'Gerenciar setores':'Fechar'}}">
      <button class="btn btn-sm py-4 px-3">
        <i class="fal fa-sign-in"></i>
      </button>
      <div>Setores</div>
    </div>
  </div>
  <div class="col  px-5 pt-4">
    <div class="d-flex justify-content-between mb-4">
      <div class="d-flex">
        <h5>Gestão SIN</h5> &nbsp;&nbsp;
        <div style="width: 20em;" class="mt-n2" *ngIf="teamsToFilter?.length>0">
          <ng-select [items]="teamsToFilter" [clearable]=false bindLabel="name" class="bg-white"
            (change)="filterTickets()"
            [multiple]="false"
            [(ngModel)]="currentTeamToFilter"
            placeholder="Filtrar setor">
          </ng-select>
        </div>
        <button class="btn btn-sm btn-primary mr-2  mt-n2" *ngIf="currentTeamToFilter" ngbTooltip="Limpar filtro"
          (click)="[currentTeamToFilter=null,filterTickets()]">
          <i class="fad fa-broom"></i>
        </button>
      </div>
      <div>
        <button class="btn btn-dark btn-sm  btn--con" (click)="loadTickets()"><i class=" fad fa-sync-alt"
            [ngClass]="{'fa-spin': loading}"></i></button>&nbsp;&nbsp;&nbsp;
        <button class="btn btn-outline-primary btn-sm" [routerLink]="['/sin']"><i
            class="fad fa-people-carry"></i> Meu
          SIN</button>
      </div>
    </div>
    <hr>
    <div class="row no-gutters justify-content-center tickets">
      <div class="col-3" *ngIf="tickets?.late?.length" style="min-height: 80vh;">
        <div class="ticket-list">
          <div class="list-header header-late">
            Chamados Atrasados
          </div>
          <div *ngTemplateOutlet="myTemplateRef; context:{ticketList: tickets.late}">
          </div>
        </div>
      </div>
      <div class="col-3" *ngIf="tickets?.new?.length" style="min-height: 80vh;">
        <div class="ticket-list">
          <div class="list-header header-new ">
            Chamados novos
          </div>
          <div *ngTemplateOutlet="myTemplateRef; context:{ticketList: tickets.new}">
          </div>
        </div>

      </div>
      <div class="col-3" *ngIf="tickets?.inProgress?.length" style="min-height: 80vh;">
        <div class="ticket-list">
          <div class="list-header header-in-progress">
            Chamados em atendimento
          </div>
          <div *ngTemplateOutlet="myTemplateRef; context:{ticketList: tickets.inProgress}">
          </div>
        </div>
      </div>
      <div class="col-3" *ngIf="tickets?.finished?.length" style="min-height: 80vh;">
        <div class="ticket-list">
          <div class="list-header header-finished">
            Finalizados
          </div>
          <div *ngTemplateOutlet="myTemplateRef; context:{ticketList: tickets.finished}">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-loading [show]="loading"></ngx-loading>


<ng-template let-ticketList="ticketList" #myTemplateRef>
  <ng-scrollbar [visibility]="'hover'" style="max-height:75vh; min-height:18vh; overflow-y: auto;">
    <div class="ticket" *ngFor="let ticket of ticketList" (click)="readTicket(ticket)" ngbTooltip="{{ticket?.previa}}"
      container="body">
      <div class="d-flex pr-2 justify-content-between">
        <b> {{ticket?.subject}}
          <span class="badge badge-sm badge-light" *ngIf=" ticket?.images?.length>0">
            <i class="fad fa-images"></i> &nbsp; {{ticket?.images?.length}}
          </span>
        </b>
        <span class="badge badge-sm badge-light">
          N° {{ticket?.number}}
        </span>

      </div>
      <small>De: </small>
      <small> {{ticket?.requester?.name}}</small> <br>
      <small>Para: </small>
      <small *ngIf="!ticket?.assignedTo?.name">Setor:
        <span class="badge  badge-resp">{{ticket?.teamRequested?.name}}</span>
      </small>
      <small *ngIf="ticket?.assignedTo?.name">
        <span class="badge  badge-resp"> <i class="fal fa-user"></i>
          {{ticket?.assignedTo?.name}}</span>
      </small>
      <hr *ngIf="ticket?.status!=='Finalizado'">

      <div class=" py-1 px-2 mt-2" *ngIf="ticket?.status!=='Finalizado'">
        <small class=" font-weight-bold text-sm"
          [ngClass]=" {'text-white badge bg-info p-1': ticket?.status==='Atendido'}"
          style="text-transform: lowercase;"
          *ngIf="ticket?.status==='Atendido'">
          <i class="fal fa-alarm-exclamation"></i>
          Aguardando {{ticket?.requester?.name }} finalizar {{ticket?.attendedAt?.toDate() | amTimeAgo}}
        </small>
        <small class=" font-weight-bold text-sm"
          [ngClass]=" {'badge badge-danger  p-1': (!ticket?.ms || ticket?.ms<=0)}"
          style="text-transform: lowercase;"
          *ngIf="ticket?.status==='Novo' && ticket?.mustStartsAt ">
          <i class="fal fa-alarm-exclamation"></i>
          {{(ticket?.ms>0) ? 'deve iniciar até ' + (ticket?.mustStartsAt?.toDate() | amCalendar) : 'Atrasado ' + (ticket?.mustStartsAt?.toDate() | amTimeAgo)}}
        </small>
        <small class=" font-weight-bold text-sm"
          [ngClass]="{'badge badge-danger  p-1': (!ticket?.ma || ticket?.ma<=0)}"
          style="text-transform: lowercase;" *ngIf="ticket?.status==='Em andamento' && ticket?.mustAttendsAt">
          <i class="fal fa-alarm-exclamation"></i>

          {{(ticket?.ma>0) ? 'deve atender até ' + (ticket?.mustAttendsAt?.toDate() | amCalendar) : 'Atrasado ' + (ticket?.mustAttendsAt?.toDate() | amTimeAgo) }}
        </small>
      </div>
    </div>
    <br>
    <br>
    <br>
  </ng-scrollbar>
</ng-template>