<div class="mt-n4">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="row justify-content-center">

    <div class="col-11">
      <div class="row">
        <div class="col px-5 pt-5">
          <h4 class="title mb-0">SIN - Solicitações Internas Nutribem</h4>
          <br>
          <span hidden>Gerencie as requisições atribuídos a você e a seu setor
          </span>

          <div class="row justify-content-between">
            <div class="col">



              <div class="mt-3 mb-4">
                <button (click)="filter()" [ngClass]="{'active': !currentStatus}"
                  class="btn btn-top active">
                  <i class="fad fa-user-tag"></i>&nbsp;
                  Para você <span *ngIf="!currentStatus&&!loadingTickets">&nbsp;({{stats?.all}})</span> </button>
                <button (click)="filter('expiring')" [ngClass]="{'active': currentStatus==='expiring'}"
                  class="btn btn-top" *ngIf="stats?.expiring>0">Prestes a vencer ({{stats?.expiring}})</button>
                <button (click)="loadForwardedByMe()" [ngClass]="{'active': currentStatus==='forwardedByMe'}"
                  class="btn btn-top" *ngIf="stats?.forwardedByMe>0">Encaminhados por mim
                  ({{stats?.forwardedByMe}})</button>
                <button (click)="filter('expired')" *ngIf="stats?.expired>0"
                  [ngClass]="{'active': currentStatus==='expired'}"
                  class="btn btn-top">Atrasados &nbsp;
                  <span class="badge badge-sm badge-danger">{{stats?.expired}}</span> </button>

                <button (click)="filter('Finalizado')" [ngClass]="{'active': currentStatus==='Finalizado'}"
                  *ngIf="stats?.finished"
                  class="btn btn-top">Finalizados ({{stats?.finished}})</button>
                <button (click)="loadMy()" [ngClass]="{'active': currentStatus==='fromMe'}"
                  class="btn btn-top">Abertos por mim</button>
              </div>
            </div>
            <div class="col-auto">
              <div class="btn-group btn-group-toggle  mt-3 mb-n3 "
                *ngIf="currentStatus==='fromMe'" ngbRadioGroup
                name="radioBasic"
                [(ngModel)]="actives">
                <label ngbButtonLabel class="btn" [ngClass]="{'btn-primary': actives==false}">
                  <input ngbButton type="radio" [value]="false" (click)="[actives=false, loadMy()]"> Todos
                </label>
                <label ngbButtonLabel class="btn" [ngClass]="{'btn-primary': actives==true}">
                  <input ngbButton type="radio" [value]="true" (click)="[actives=true, loadMy()]"> Em andamento
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto pt-5 pr-5">
          <div class='onesignal-customlink-container'></div>
        </div>
      </div>

      <div *ngIf="!loading">
        <div class="card mx-4 px-4 pb-2 pt-1 issue-tracker " style="overflow: hidden; min-height: 60vh;  ">
          <div *ngIf="loadingTickets" style="display: flex;height: 50vh;">
            <div class="page-loader__spinner">
              <svg viewBox="25 25 50 50">
                <circle cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
              </svg>
            </div>
          </div>
          <div class="listview listview--bordered animated fadeIn"
            *ngIf="!loadingTickets&&tickets.filtered && tickets.filtered?.length>0">
            <table datatable [dtOptions]="dtOptions" class="table table-hover  mt-n5 mb-0 border-bottom-0">
              <thead>
                <tr>
                  <th class="pt-4 text-center">#</th>
                  <th>Assunto</th>
                  <th class="text-center">Status</th>
                  <th>Criado por</th>
                  <th>Para</th>
                  <th>Prazo</th>
                  <th>Ultima Atualização</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ticket of tickets.filtered; let i = index;" (click)="readTicket(ticket)"
                  class="animatd faster fadeInUp py-0 delay-{{i+1}}"
                  ngbTooltip="{{ticket?.previa}}"
                  container="body"
                  [ngStyle]="{'animation-delay': 0.004*(i+1)+'s'}">
                  <td scope="row" class="text-center"> {{ticket?.number}}</td>
                  <td> {{ticket?.subject}}
                    <span class="badge badge-sm badge-light" *ngIf=" ticket?.images?.length>0">
                      <i class="fad fa-images"></i> &nbsp; {{ticket?.images?.length}}
                    </span>

                  </td>
                  <td class="text-center"
                    [ngClass]="{'pb-0': ticket?.status==='Novo' && currentUser?.id!==ticket?.requester?.id}">
                    <span class="badge p-2"
                      [ngClass]="{'badge-soft-info': ticket?.status==='Novo','badge-soft-success': ticket?.status==='Em andamento','badge-soft-danger': ticket?.status==='Novo' && ticket?.expire<=0}">
                      {{ticket?.status}}
                    </span>
                    <br>
                    <span class="badge badge-danger"
                      *ngIf="currentStatus==='fromMe'&&ticket?.status==='Atendido'||ticket?.status==='Atendido'">Feche
                      o chamado!</span>

                  </td>
                  <td>{{ticket?.requester?.name}} <small class=" text-muted">-
                      {{ticket?.createdAt?.toDate()| amTimeAgo}}</small></td>
                  <td class="text-center">
                    <small *ngIf="!ticket?.assignedTo?.name">Setor:
                      <span class="badge  badge-resp">{{ticket?.teamRequested?.name}}</span>
                    </small>
                    <small *ngIf="ticket?.assignedTo?.name">
                      <span class="badge  badge-resp"> <i class="fal fa-user"></i>
                        {{ticket?.assignedTo?.name}}</span>
                    </small>
                  </td>
                  <td class="text-center">
                    <small class=" font-weight-bold text-sm"
                      [ngClass]=" {'text-white badge badge-secondary p-1': ticket?.status==='Atendido'}"
                      *ngIf="ticket?.status==='Atendido'">
                      <i class="fal fa-alarm-exclamation"></i>
                      Aguardando {{ticket?.requester?.name }} finalizar <span
                        *ngIf="ticket?.mustEndsAt">{{ticket?.mustEndsAt?.toDate() | amTimeAgo}}</span>
                    </small>
                    <small class=" font-weight-bold text-sm"
                      [ngClass]="
                    {' badge badge-danger ': (!ticket?.ms || ticket?.ms<=0)}"
                      style="text-transform: lowercase;"
                      *ngIf="ticket?.status==='Novo' &&ticket?.ms ">
                      <i class="fal fa-alarm-exclamation"></i>
                      {{(ticket?.ms>0) ? 'iniciar até ' + (ticket?.mustStartsAt?.toDate() | amCalendar) : 'Atrasado ' + (ticket?.mustStartsAt?.toDate() | amTimeAgo)}}
                    </small>
                    <small class=" font-weight-bold text-sm"
                      [ngClass]="
                  {'text-danger': (!ticket?.ma || ticket?.ma<=0)}"
                      style="text-transform: lowercase;"
                      *ngIf="ticket?.status==='Em andamento' &&  ticket?.ma ">
                      <i class="fal fa-alarm-exclamation"></i>
                      {{(ticket?.ma>0) ? 'atender até ' + (ticket?.mustAttendsAt?.toDate() | amCalendar) : 'Atrasado ' + (ticket?.mustAttendsAt?.toDate() | amTimeAgo) }}
                    </small>
                  </td>
                  <td title="{{ticket?.id}}">
                    <small>

                      {{ticket?.updatedAt?.toDate()| date: 'dd/MM HH:mm'}}
                    </small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="placeholder my-5 py-5"
            *ngIf="!loadingTickets && (!tickets.filtered || tickets.filtered.length===0)"
            style="opacity: 1;">
            <br>
            <br>
            <img
              src="assets/images/placeholder/vacations.svg">
            <h6 class="font-weigth-600">Nenhum requisição de acordo com o filtro aplicado. Bom trabalho!
            </h6> <br>
          </div>

        </div>
      </div>
      <div *ngIf="ticketSelected">
        <br>
        <header class="">
          <button class="btn btn-link" (click)="ticketSelected=null"><i class="fa fa-arrow-left"></i> Voltar</button>
        </header>
        <br>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>

  <button class="btn btn-primary btn-lg btn--action " (click)="quickModal.show=!quickModal.show"
    data-toggle="modal"
    data-target="#add-note">
    <i class="fal" [ngClass]="{'fa-plus': !quickModal.show,'fa-times': quickModal.show }"></i>
  </button>
</div>


<div class="quick-modal px-2 animated faster fadeInUp" *ngIf="quickModal.show"
  [ngClass]="{'minimized':  quickModal.minimized}">
  <div class="modal-body px-5" style="max-height: 90vh; overflow: scroll;">
    <div class="d-flex w-100 justify-content-between">
      <h4 class="modal-title mb-4" id="modal-basic-title">Nova requisição</h4>
      <div class="d-flex">
        <button type="button" hidden class="btn btn-sm btn-link" *ngIf="!quickModal.minimized" aria-label="Close"
          (click)="quickModal.minimized=true">
          <i class="fad fa-window-minimize"></i>
        </button>
        <button type="button" class="btn btn-sm btn-link" *ngIf="quickModal.minimized" aria-label="Close"
          (click)="quickModal.minimized=false">
          <i class="fal fa-window-maximize"></i>
        </button>
        <button type="button" class="btn btn-sm btn-link" aria-label="Close" (click)="quickModal.show=false">
          <i class="fal fa-2x fa-times"></i>
        </button>
      </div>
    </div>

    <div class="form-group">
      <span>Situação:</span>
      <div role="group" class="btn-group btn-group-sm mb-0 ml-1 ">
        <button (click)="newTicket.type = 'Nova'" class="btn btn-outline-primary"
          [ngClass]="{'btn-primary text-white': newTicket?.type == 'Nova'}">Nova</button>
        <button (click)="newTicket.type = 'Recorrente'" class="btn btn-outline-primary"
          [ngClass]="{'btn-primary text-white': newTicket?.type == 'Recorrente'}">Recorrente</button>
      </div>
    </div>
    <div class="form-group">
      <ng-select [items]="teams.all" placeholder="Escolha um setor para abrir a requisição" [clearable]="false"
        appendTo="body"
        class="pb-0"
        [(ngModel)]="newTicket.teamRequested"
        notFoundText="Sem resultados"
        bindLabel="name"></ng-select>
    </div>
    <div class="form-group">
      <input name="newTicketName" type="text" id="newTicketName" placeholder="Sobre o quê deseja tratar"
        [(ngModel)]="newTicket.subject" autofocus
        class="form-control">
    </div>
    <div class="form-group" style="border: 1px solid #0000002e;border-radius: 3px;">
      <ckeditor [editor]="Editor" placeholder="Descreva detalhadamente sua requisição"
         [config]="config" [(ngModel)]="newTicket.message">
      </ckeditor>

      <textarea id="satisfaction" hidden rows="4" placeholder="Descreva detalhadamente sua requisição"
        [(ngModel)]="newTicket.message" class="form-control "></textarea>
    </div>
    <div class="form-group">
      <span>Prioridade:</span>
      <div role="group" class="btn-group btn-group-sm mb-0 ml-1 ">
        <button (click)="newTicket.priority = 'Baixa'" class="btn btn-outline-primary"
          [ngClass]="{'btn-primary text-white': newTicket?.priority == 'Baixa'}">Baixa</button>
        <button (click)="newTicket.priority = 'Media'" class="btn btn-outline-primary"
          [ngClass]="{'btn-primary text-white': newTicket?.priority == 'Media'}">Media</button>
        <button (click)="newTicket.priority = 'Alta'" class="btn btn-outline-primary"
          [ngClass]="{'btn-primary text-white': newTicket?.priority == 'Alta'}">Alta</button>
      </div>
    </div>
    <div class="form-group">
      <span class="btn-pipefy" style="cursor: pointer;" (click)="showDropzone=!showDropzone">
        <i class="fal fa-paperclip"></i> &nbsp;
        Anexos</span>
    </div>
    <div class="form-group" *ngIf="showDropzone">
      <ngx-dropzone (change)="onSelect($event)" [expandable]="true">
        <ngx-dropzone-label>Solte arquivos para anexar, ou clique</ngx-dropzone-label>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
          [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
      </ngx-dropzone>
    </div>
  </div>
  <div class="">
    <button type="button" class="btn btn-sm btn-primary  btn-block py-4" style="font-weight: 1em;" (click)="add()">Abrir
      requisição</button>
  </div>
</div>